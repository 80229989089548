import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Responsive from 'react-responsive';
import ModalHeader from 'components/organisms/modalHeader/index.jsx';
import MobileHeader from 'components/organisms/mobileHeader/index.jsx';
import MobileActions from 'components/organisms/mobileActions/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import Select from 'components/molecules/select/index.jsx';
import Loader from 'components/atoms/loader';
import '../../../DigitalDealership.scss';

import { parseRoute } from 'config/routes';
import { display as displayPrice } from 'helpers/price';
import * as dealershipActions from 'actions/dealership';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

const apiDomain = process.env.REACT_APP_API_URL;

const apiUrl = apiDomain + '/api/v2/territorio/dealership';

class AddUsedVehicle extends React.Component {
  state = {
    brands: [],
    models: [],
    vehicles: [],
    brand_id: '',
    model: '',
    codia: '',
    kms: '',
    year: '',
    requesting: false,
    error: false,
    valuation: undefined,
    done: false
  };

  constructor(props) {
    super(props);

    this.handleBrandChange = this.handleChange.bind(this, 'brand_id');
    this.handleModelChange = this.handleChange.bind(this, 'model');
    this.handleVehicleChange = this.handleChange.bind(this, 'codia');
    this.handleKmsChange = this.handleEventChange.bind(this);
    this.handleYearChange = this.handleEventChange.bind(this);
    this.requestValuation = this.requestValuation.bind(this);
    this.retry = this.retry.bind(this);
    this.acceptValuation = this.acceptValuation.bind(this);
  }

  handleEventChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange(property, item) {
    this.setState({
      [property]: item.value,
      [`${property}_name`]: item.title
    });

    if (property === 'brand_id') {
      this.setState({ models: [], vehicles: [] });
      this.fetchModels(item.value);
    } else if (property === 'model') {
      this.setState({ vehicles: [] });
      this.fetchVehicles(item.value);
    }
  }

  componentDidMount() {
    this.fetchBrands();
  }

  requestValuation() {
    const data = {
      codia: this.state.codia,
      brand: this.state.brand_id_name,
      model: this.state.model_name,
      vehicle: this.state.codia_name,
      year: this.state.year,
      kms: this.state.kms,
      operation: this.props.operation.code
    };

    this.setState({
      requesting: true
    });

    fetch(`${apiUrl}/valuation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => response.data)
      .then(valuation => {
        this.setState({
          requesting: false,
          error: true,
          valuation
        });
      })
      .catch(e => {
        console.error(e);
        this.setState({
          requesting: false,
          error: true
        });
      });
  }

  fetch(endpoint) {
    return fetch(`${apiUrl}${endpoint}`, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(response => response.data);
  }

  fetchBrands() {
    this.fetch('/parameters/brands')
      .then(brands =>
        brands.map(brand => ({ title: brand.name, value: brand.id }))
      )
      .then(brands => this.setState({ brands }));
  }

  fetchModels(brand_id) {
    this.fetch(`/parameters/models?brand_id=${brand_id}`)
      .then(models =>
        models.map(model => ({ title: model.name, value: model.name }))
      )
      .then(models => this.setState({ models }));
  }

  fetchVehicles(model) {
    this.fetch(
      `/parameters/vehicles?brand_id=${this.state.brand_id}&group=${model}`
    )
      .then(vehicles =>
        vehicles.map(vehicle => ({
          title: vehicle.description,
          value: vehicle.codia,
          data: vehicle
        }))
      )
      .then(vehicles => this.setState({ vehicles }));
  }

  ready() {
    return this.state.codia && this.state.kms && this.state.year;
  }

  acceptValuation() {
    const { operation } = this.props;

    operation.addUsedVehicle({
      brand: this.state.brand_id_name,
      vehicle: `${this.state.model_name} ${this.state.codia_name}`,
      kms: this.state.kms,
      year: this.state.year,
      valuation: this.state.valuation
    });

    this.props.updateOperation(operation);

    this.setState({ done: true });
  }

  retry() {
    this.setState({
      error: false,
      valuation: undefined
    });
  }

  render() {
    const {
      brands,
      models,
      vehicles,
      kms,
      year,
      valuation,
      error,
      requesting,
      done
    } = this.state;
    const { vehicle, operation } = this.props;
    const { operation_code } = this.props.match.params;

    if (!operation || !vehicle) {
      return (
        <Redirect
          to={parseRoute('dealership_resume', {
            operation_code: operation_code
          })}
        />
      );
    }

    if (done) {
      return (
        <Redirect
          to={parseRoute('dealership_payment_selection', {
            operation_code: operation_code
          })}
        />
      );
    }

    if (valuation) {
      return this.renderValuation();
    }

    return (
      <div className="digital-dealership">
        <Desktop>
          <ModalHeader
            title="Agregar Usado"
            type="back"
            link={parseRoute('dealership_payment_selection', {
              operation_code
            })}
          />
        </Desktop>
        <Mobile>
          <MobileHeader
            title="Agregar Usado"
            type="back"
            link={parseRoute('dealership_payment_selection', {
              operation_code
            })}
          />
          <MobileActions
            variant="main"
            text="Cotizar"
            disabled={!this.ready()}
            onClick={this.requestValuation}
          />
        </Mobile>

        <Desktop>
          <div className="section section-big digital-dealership__modal-content">
            <div className="container">
              <div className="col col-three col-not-mobile" />
              <div className="col col-six">
                <h2>Completá los datos del vehículo usado</h2>

                <div className="digital-dealership__fieldset">
                  <div>
                    {!brands.length && <div>Cargando marcas...</div>}

                    <Select
                      label="Marca"
                      list={brands}
                      third
                      onChange={this.handleBrandChange}
                      disabled={!brands.length}
                    />

                    <Select
                      label="Modelo"
                      list={models}
                      third
                      onChange={this.handleModelChange}
                      disabled={!models.length}
                    />

                    <Select
                      label="Version"
                      list={vehicles}
                      third
                      onChange={this.handleVehicleChange}
                      disabled={!vehicles.length}
                    />
                  </div>
                </div>

                <div className="digital-dealership__fieldset">
                  <h4>Estado del vehículo</h4>
                  <div>
                    <Textfield
                      label="Año"
                      name="year"
                      placeholder="Ej: 2012"
                      fourth
                      onChange={this.handleYearChange}
                      value={year}
                    />
                    <Textfield
                      name="kms"
                      label="Kilometraje"
                      placeholder="Ej: 25.000"
                      half
                      onChange={this.handleKmsChange}
                      value={kms}
                    />
                  </div>
                </div>
                <div className="digital-dealership__form-action">
                  {!requesting && (
                    <Button
                      text="Cotizar"
                      variant="main"
                      disabled={!this.ready()}
                      onClick={this.requestValuation}
                    />
                  )}

                  {requesting && <Loader />}

                  {error && (
                    <p>
                      <strong>
                        No pudimos realizar la cotización de tu vehículo.
                      </strong>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Desktop>

        <Mobile>
          <div className="section section-big digital-dealership__modal-content">
            <h2>Completá los datos del vehículo usado</h2>
          </div>
          <div className="section section-big section-white">
            <div className="container ">
              <div className="digital-dealership__fieldset">
                <div className="col col-twelve">
                  <div>
                    <Select
                      label="Marca"
                      list={brands}
                      onChange={this.handleBrandChange}
                      disabled={!brands.length}
                    />
                  </div>
                  <div>
                    <Select
                      label="Modelo"
                      list={models}
                      onChange={this.handleModelChange}
                      disabled={!models.length}
                    />
                  </div>
                  <div>
                    <Select
                      label="Version"
                      list={vehicles}
                      onChange={this.handleVehicleChange}
                      disabled={!vehicles.length}
                    />
                  </div>
                </div>
              </div>
              <div className="digital-dealership__fieldset">
                <div className="col col-twelve">
                  <h4>Estado del vehículo</h4>
                  <div>
                    <Textfield
                      label="Año"
                      name="year"
                      placeholder="Ej: 2012"
                      onChange={this.handleYearChange}
                      value={year}
                    />
                  </div>
                  <div>
                    <Textfield
                      label="Kilometraje"
                      name="kms"
                      placeholder="Ej: 25.000"
                      onChange={this.handleKmsChange}
                      value={kms}
                    />
                  </div>
                </div>
                <div className="col col-twelve">
                  {requesting && <Loader />}

                  {error && (
                    <strong>
                      No pudimos realizar la cotización de tu vehículo.
                    </strong>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Mobile>
      </div>
    );
  }

  renderValuation() {
    const { operation_code } = this.props.match.params;
    const { brand_name, vehicle_name, valuation } = this.state;

    return (
      <div className="digital-dealership">
        <Desktop>
          <ModalHeader
            title="Agregar Usado"
            type="back"
            link={parseRoute('dealership_payment_selection', {
              operation_code
            })}
          />
        </Desktop>
        <Mobile>
          <MobileHeader
            title="Agregar Usado"
            type="back"
            link={parseRoute('dealership_payment_selection', {
              operation_code
            })}
          />
          <MobileActions
            variant="main"
            text="Aceptar Cotización"
            onClick={this.acceptValuation}
          />
        </Mobile>

        <div className="section section-big digital-dealership__modal-content">
          <div className="container">
            <div className="col col-three col-not-mobile" />
            <div className="col col-six col-m-twelve">
              <h5>
                Valor de: {brand_name} {vehicle_name}
              </h5>
              <h1>{displayPrice(valuation)}</h1>
              <p>
                Hemos estimado el valor de tu vehículo usado en{' '}
                {displayPrice(valuation)}. Si estas de acuerdo confirma la
                cotización para continuar.
              </p>

              <div className="digital-dealership__form-action">
                <Desktop>
                  <Button
                    text="Aceptar Cotización"
                    variant="main"
                    onClick={this.acceptValuation}
                  />
                </Desktop>
                <Button text="Cancelar" onClick={this.retry} />
              </div>

              <div>
                <p>
                  <small>
                    Esta tasación es una estimación generada por nuestro
                    sistema, no implica ningún compromiso por parte de la
                    empresa y debe ser confirmada por nuestros vendedores al
                    concretar la operación.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    operation: state.dealership.operation,
    vehicle: state.stock.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOperation: operation => dispatch(dealershipActions.update(operation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUsedVehicle);
