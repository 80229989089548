export default class DealershipOperation {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  getPendingAmount(vehicle, { cash_amount } = {}) {
    let pendingAmount = vehicle.getPromotionPrice(this.promotion);

    if (cash_amount) {
      pendingAmount -= cash_amount;
    } else if (this.cash_amount) {
      pendingAmount -= this.cash_amount;
    }

    this.used_vehicles.forEach(vehicle => {
      pendingAmount -= vehicle.valuation;
    });

    pendingAmount -= this.credit_amount;

    return pendingAmount;
  }

  getTotalAmount(vehicle) {
    return vehicle.getPromotionPrice(this.promotion);
  }

  addUsedVehicle(vehicle) {
    this.used_vehicles.push(vehicle);
  }
}
