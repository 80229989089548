import React from 'react';
import { Link } from 'react-router-dom';
import './MobileHeader.scss';

export default class MobileHeader extends React.Component {
  render() {
    const { type, title, link, infoLink, onClose, dark } = this.props;

    let className =
      'mobile-header' +
      (type ? ' mobile-header-' + type : '') +
      (infoLink ? ' mobile-header-info' : '') +
      (dark ? ' mobile-header-dark' : '') +
      (!title ? ' mobile-header-no-title' : '');

    let infoElement;
    if (infoLink && type === 'back') {
      infoElement = (
        <a className="mobile-header__info" href={infoLink}>
          Info
        </a>
      );
    }

    return (
      <div className={className}>
        {infoElement}
        {onClose && (
          <a
            className="mobile-header__link"
            href={link}
            onClick={e => {
              e.preventDefault();
              onClose();
            }}
          >
            {type}
          </a>
        )}
        {!onClose && (
          <Link className="mobile-header__link" to={link}>
            {type}
          </Link>
        )}
        <div className="mobile-header__title">{title}</div>
      </div>
    );
  }
}
