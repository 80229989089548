import fetch from 'isomorphic-fetch';

const INTERNAL_API_URL = process.env.REACT_APP_API_URL;

/* Promotion code application */
export const checkPromotionCode = ({ code, family, chassis, brand_id }) => {
  return dispatch => {
    dispatch(promotionCodeCheckRequested());

    family = family.replace(/-/g, ' ');

    return fetch(
      `${INTERNAL_API_URL}/api/promotion?code=${code}&family=${family}&chassis=${chassis}&brand_id=${brand_id}`
    )
      .then(response => response.json())
      .then(json => {
        dispatch(promotionCodeCheckSuccessful(json.data));
      })
      .catch(() => dispatch(promotionCodeCheckFailed()));
  };
};

const promotionCodeCheckRequested = response => ({
  type: 'PROMOTION_CODE_REQUESTED'
});

const promotionCodeCheckFailed = response => ({
  type: 'PROMOTION_CODE_REQUEST_FAILED'
});

const promotionCodeCheckSuccessful = promotion => ({
  type: 'PROMOTION_CODE_SUCCESSFUL',
  promotion
});

/* Promotion code discovery */
export const discoverPromotionCode = ({ family, chassis, brand_id }) => {
  return dispatch => {
    dispatch(promotionCodeDiscoverRequested());

    family = family.replace(/-/g, ' ');

    return fetch(
      `${INTERNAL_API_URL}/api/promotion/discover?family=${family}&chassis=${chassis}&brand_id=${brand_id}`
    )
      .then(response => response.json())
      .then(json => {
        dispatch(promotionCodeDiscoverSuccessful(json.data));
      });
  };
};

const promotionCodeDiscoverRequested = response => ({
  type: 'PROMOTION_CODE_DISCOVER_REQUESTED'
});

const promotionCodeDiscoverSuccessful = promotion => ({
  type: 'PROMOTION_CODE_DISCOVER_SUCCESSFUL',
  promotion
});
