import React from 'react';
import './Check.scss';

export default class Check extends React.Component {
  constructor() {
    super();

    this.state = {
      stateClass: 'check-hide'
    };
  }

  componentDidMount() {
    this.setState({ stateClass: 'check-show' });
  }
  render() {
    let className = 'check ' + this.state.stateClass;
    //(color ? ' check-' + color : '') +
    //(small ? ' check-small' : '');

    return (
      <div className={className}>
        <div className="check__checkElement" />
      </div>
    );
  }
}
