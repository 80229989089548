import React from 'react';
import { Link } from 'react-router-dom';
import ybrand from 'resources/img/logo-y-orange.svg';
import './FlowHeader.scss';

export default class FlowHeader extends React.Component {
  render() {
    const { center, small, title, subtitle } = this.props;

    let className =
      'flow-header' +
      (center ? ' flow-header-center' : '') +
      (small ? ' flow-header-small' : '');

    var dataElement;
    if (title && subtitle) {
      dataElement = (
        <div className="flow-header__data">
          <h4>{title}</h4>
          <h5>{subtitle}</h5>
        </div>
      );
    }

    return (
      <div className={className}>
        <div className="flow-header__brand">
          <Link to="/">
            <img src={ybrand} alt="Territorio Yacopini" />
          </Link>
        </div>
        {dataElement}
      </div>
    );
  }
}
