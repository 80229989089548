import { getExcerpt } from "helpers/news";
import { slugify } from "helpers/url";

export default class News {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  get excerpt() {
    return getExcerpt(this.content);
  }

  get slug_url() {
    const title = slugify(this.title);
    return `/noticias/${this.id}/${title}`;
  }
}
