export const display = rawPrice => {
  const price = parseFloat(rawPrice);

  if (!price) {
    return 'no disponible';
  }

  return price.toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0
  });
};

export const displayUSD = (price) => {
  if (!price) {
    return "";
  }

  return price.toLocaleString("es-AR", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
};
