import React from 'react';
import Breadcrumbs from 'components/molecules/breadcrumbs/index.jsx';
import './Hero.scss';

export default class Hero extends React.Component {
  render() {
    const {
      type,
      color,
      big,
      small,
      image,
      imageAlt,
      dark,
      breadcrumbs,
      smallMobile
    } = this.props;

    let className =
      'hero ' +
      (type ? ' hero-' + type : '') +
      (big ? ' hero-big' : '') +
      (small ? ' hero-small' : '') +
      (smallMobile ? ' hero-small-mobile' : '') +
      (dark ? ' hero-dark' : '') +
      (color ? ' hero-' + color : '') +
      (breadcrumbs ? ' hero-breadcrumbs' : '') +
      (type === 'color' ? ' gradient' : '');
    //IMAGE FOR IMAGE TYPE
    var imageElement;
    if (image && type === 'image') {
      imageElement = (
        <div className="hero__image">
          <img src={image} alt={imageAlt} />
        </div>
      );
    }
    //IMAGE FOR BACKGROUND TYPE
    if (image && type === 'background') {
      var stageBgr = {
        backgroundImage: "url('" + image + "')"
      };
    }

    //Breadcrumbs Element
    var breadcrumbsElement;
    if (breadcrumbs) {
      breadcrumbsElement = (
        <div className="hero__breadcrumbs">
          <div className="container">
            <div className="col col-twelve">
              <Breadcrumbs sections={breadcrumbs} dark={dark} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={className} style={stageBgr}>
        {breadcrumbsElement}
        {imageElement}
        <div className="container">{this.props.children}</div>
      </div>
    );
  }
}
