import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Responsive from 'react-responsive';
import ModalHeader from 'components/organisms/modalHeader/index.jsx';
import MobileHeader from 'components/organisms/mobileHeader/index.jsx';
import MobileActions from 'components/organisms/mobileActions/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import SelectCard from 'components/molecules/selectCard/index.jsx';
import Loader from 'components/atoms/loader';

import * as dealershipActions from 'actions/dealership';
import creditsActions from 'actions/credits';
import { parseRoute } from 'config/routes';
import { display as displayPrice } from 'helpers/price';

import '../../../DigitalDealership.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

class SelectPlan extends React.Component {
  constructor(props) {
    super(props);

    const { vehicle, operation } = props;
    const loan_amount =
      vehicle && operation
        ? operation.credit_amount || operation.getPendingAmount(vehicle)
        : 0;

    this.state = {
      loan_amount,
      allCredits: null,
      selectedCreditName: operation ? operation.credit_name : null
    };

    this.handleLoanChange = this.handleLoanChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { vehicle, operation } = this.props;

    if (!operation) {
      return;
    }

    this.props.fetchCredits({
      brand_id: operation.brand_id,
      vehicle: vehicle.name
    });
  }

  componentDidUpdate() {
    if (!this.state.allCredits && this.props.credits) {
      this.setState({
        allCredits: this.props.credits
      });

      const { vehicle, operation } = this.props;

      this.props.fetchCredits({
        brand_id: operation.brand_id,
        vehicle: vehicle.name,
        loan_amount: this.state.loan_amount
      });
    }
  }

  selectUnavailableCredit(credit) {
    const { operation } = this.props;

    this.handleLoanChange({
      target: {
        value: credit.financed_max
      }
    });

    this.setState({
      selectedCreditName: `${credit.name} ${credit.months}`
    });

    operation.credit_id = credit.id;
    operation.credit_name = credit.name;
    operation.credit_amount = credit.financed_max;
    operation.credit_installments = credit.months;
    operation.credit_avg_installment = credit.avg_installment;

    this.props.updateOperation(operation);
  }

  selectCredit(credit) {
    const creditName = `${credit.name} ${credit.months}`;
    const selectedCreditName =
      this.state.selectedCreditName === creditName ? null : creditName;

    this.setState({
      selectedCreditName
    });

    const { operation } = this.props;

    if (selectedCreditName) {
      operation.credit_id = credit.id;
      operation.credit_name = credit.name;
      operation.credit_amount = this.state.loan_amount;
      operation.credit_installments = credit.months;
      operation.credit_avg_installment = credit.avg_installment;
    } else {
      operation.credit_id = 0;
      operation.credit_name = '';
      operation.credit_amount = 0;
      operation.credit_installments = 0;
      operation.credit_avg_installment = 0;
    }

    this.props.updateOperation(operation);
  }

  handleLoanChange(e) {
    const amount = parseFloat(e.target.value) || 0;

    this.setState({
      loan_amount: amount
    });

    this.props.fetchCredits({
      brand_id: this.props.operation.brand_id,
      vehicle: this.props.vehicle.name,
      loan_amount: amount
    });
  }

  render() {
    const { vehicle, operation, credits } = this.props;
    const { operation_code } = this.props.match.params;
    const { loan_amount, selectedCreditName } = this.state;
    const otherCredits = this.getOtherCredits();

    if (!operation || !vehicle) {
      return (
        <Redirect
          to={parseRoute('dealership_resume', {
            operation_code: operation_code
          })}
        />
      );
    }

    return (
      <div className="digital-dealership">
        <Desktop>
          <ModalHeader
            title="Agregar financiación"
            type="back"
            link={parseRoute('dealership_payment_selection', {
              operation_code
            })}
          />
        </Desktop>
        <Mobile>
          <MobileHeader
            title="Agregar financiación"
            type="back"
            link={parseRoute('dealership_payment_selection', {
              operation_code
            })}
          />
          <MobileActions
            variant="main"
            text={
              selectedCreditName
                ? 'Agregar financiación'
                : 'Volver sin financiación'
            }
            link={parseRoute('dealership_payment_selection', {
              operation_code
            })}
          />
        </Mobile>

        <div className="section section-big digital-dealership__modal-content">
          <div className="container">
            <div className="col col-three col-not-mobile" />
            <div className="col col-six col-m-twelve">
              <h2>Selecciona una financiación</h2>
              <p>
                Podés cambiar el monto a financiar y acceder a diferentes
                planes.{' '}
              </p>

              <div className="digital-dealership__fieldset">
                <h4>Monto a financiar</h4>
                <Textfield
                  value={loan_amount}
                  third
                  onChange={this.handleLoanChange}
                />
              </div>
              <div className="digital-dealership__fieldset">
                <h4>Selecciona un plan</h4>
                <div className="digital-dealership__cards-container">
                  {credits === null && <Loader />}

                  {credits && credits.length === 0 && (
                    <div>
                      No encontramos financiación disponible para el vehículo
                      seleccionado y el importe ingresado. Podés volver a
                      intentar <strong>disminuyendo el importe</strong> a
                      financiar.
                    </div>
                  )}

                  {credits &&
                    credits.map((credit, i) => (
                      <SelectCard
                        text={credit.name}
                        value={`${credit.name} ${credit.months}`}
                        selectedValue={selectedCreditName}
                        full
                        helpText={
                          <div>
                            {`Cantidad de cuotas ${credit.months}`}
                            <br />
                            {`Cuota promedio ${displayPrice(
                              credit.avg_installment
                            )}`}
                            <br />
                            {`Hasta ${displayPrice(credit.financed_max)}. ${
                              credit.bank
                            }`}
                          </div>
                        }
                        key={i}
                        onClick={() => this.selectCredit(credit)}
                      />
                    ))}

                  {otherCredits.map(credit => (
                    <SelectCard
                      text={credit.name}
                      disabled
                      full
                      helpText={
                        <div>
                          {`Cantidad de cuotas ${credit.months}`}
                          <br />
                          {`Cuota promedio ${displayPrice(
                            credit.avg_installment
                          )}`}
                          <br />
                          {`Hasta ${displayPrice(credit.financed_max)}. ${
                            credit.bank
                          }`}
                        </div>
                      }
                      key={credit.id}
                      onClick={() => this.selectUnavailableCredit(credit)}
                    />
                  ))}
                </div>
              </div>

              <Desktop>
                <div className="digital-dealership__form-action">
                  <Link
                    to={parseRoute('dealership_payment_selection', {
                      operation_code: operation.code
                    })}
                  >
                    {selectedCreditName === null && (
                      <Button text="Volver sin financiación" variant="main" />
                    )}
                    {selectedCreditName !== null && (
                      <Button text="Agregar financiación" variant="main" />
                    )}
                  </Link>
                </div>
              </Desktop>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getOtherCredits() {
    const { credits } = this.props;
    const { allCredits } = this.state;

    if (!credits || !allCredits || !allCredits.length) {
      return [];
    }

    const displayedCredits = credits.map(credit => credit.id);

    return allCredits.filter(credit => displayedCredits.indexOf(credit.id) < 0);
  }
}

const mapStateToProps = state => {
  return {
    operation: state.dealership.operation,
    vehicle: state.stock.selected,
    credits: state.credits.all
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOperation: operation => dispatch(dealershipActions.update(operation)),
    fetchCredits: query => dispatch(creditsActions.fetchAll({ query }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlan);
