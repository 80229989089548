import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ybrand from 'resources/img/logo-y-orange.svg';
import facebook from 'resources/img/facebook.svg';
import instagram from 'resources/img/instagram.svg';
import whatsapp from 'resources/img/whatsapp.svg';
import './Footer.scss';

var Footer = props => {
  return (
    <div className={'footer' + (props.alt ? ' footer-alt' : '')}>
      <div className="container">
        <div className="col col-six col-m-twelve">
          <div className="footer__logo">
            <Link to="/">
              <img src={ybrand} alt="Territorio Yacopini" />
            </Link>
          </div>
        </div>
        <div className="footer__info col col-six col-m-twelve">
          <div className="footer__info__text">
            Llamános al +54 261 4439300
            <br />
            San Martín Sur 600. Godoy Cruz. Mendoza
          </div>
          <div className="footer__info__social">
            <a
              href="https://www.instagram.com/territorioyacopinimendoza/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" />
            </a>
            <a
              href="https://www.facebook.com/TerritorioYacopiniMendoza/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" />
            </a>
            <a
              href="https://web.whatsapp.com/send?phone=542614687996"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="WhatsApp" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect()(Footer);
