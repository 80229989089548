import Family from 'models/family';
import SavingPlan from 'models/savingPlan';
import Vehicle from 'models/vehicle';
import { applyFamilyImage } from 'helpers/vehicle';

export const fetchAll = (
  resource,
  ClassName,
  defaultStateValue = null,
  options = {}
) => {
  defaultStateValue = defaultStateValue || null;

  const defaultState = {
    requesting: false,
    error: false,
    notFound: false,
    all: defaultStateValue,
    grouped: {},
    selected: null
  };

  const actionName = resource.toUpperCase();

  return function reducer(state = defaultState, action) {
    switch (action.type) {
      case `FETCH_ALL_${actionName}_RESET`:
        return { ...defaultState };

      case `FETCH_ALL_${actionName}_SENT`:
        return { ...state, ...defaultState, requesting: true };

      case `FETCH_ALL_${actionName}_FAILED`:
        return { ...state, requesting: false, error: true };

      case `FETCH_ALL_${actionName}_SUCCESS`:
        let all = state.all;
        let grouped = { ...state.grouped };

        try {
          var payload = transformPayload(action.payload, ClassName);
        } catch (e) {
          console.error(e);
        }

        if (options.group) {
          all = all.concat(payload);
          grouped[action.brandId] = payload;
        } else {
          all = payload;
        }

        return {
          ...state,
          grouped,
          all,
          requesting: false
        };

      case 'SET_VEHICLE_IMAGES':
        if (
          state.all &&
          state.all.length > 0 &&
          (state.all[0] instanceof Vehicle ||
            state.all[0] instanceof Family ||
            state.all[0] instanceof SavingPlan)
        ) {
          return {
            ...state,
            all: applyFamilyImage(state.all, action.vehicles)
          };
        }

        return state;

      case `FETCH_ONE_${actionName}_SENT`:
        return { ...state, selected: null, requesting: true };

      case `FETCH_ONE_${actionName}_FAILED`:
        return { ...state, requesting: false, error: true };

      case `FETCH_ONE_${actionName}_SUCCESS`:
        return {
          ...state,
          selected: ClassName ? new ClassName(action.payload) : action.payload
        };

      default:
        return state;
    }
  };
};

const transformPayload = (payload, ClassName) => {
  if (!ClassName) {
    return payload;
  }

  if (typeof ClassName === 'object') {
    for (const attr in ClassName) {
      payload[attr] = transformPayload(payload[attr], ClassName[attr]);
    }

    return payload;
  }

  try {
    return payload.map(data => new ClassName(data));
  } catch (e) {
    console.error(e);
  }

  return payload;
};
