import React from 'react';
import Check from 'components/atoms/check/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Select from 'components/molecules/select/index.jsx';
import Textarea from 'components/molecules/textarea/index.jsx';
import Button from 'components/atoms/button/index.jsx';

export default ({ success, error }) => {
  var options = {
    education: [
      {
        title: 'Primarios',
        value: 'Primario'
      },
      {
        title: 'Secundarios',
        value: 'Secundario'
      },
      {
        title: 'Terciarios',
        value: 'Terciario'
      },
      {
        title: 'Universitarios Completos',
        value: 'Universitario'
      }
    ],
    location: [
      {
        title: 'Gran Mendoza',
        value: 'Gran Mendoza'
      },
      {
        title: 'San Rafael',
        value: 'San Rafael'
      },
      {
        title: 'San Martin',
        value: 'San Martin'
      },
      {
        title: 'Otro',
        value: 'Otro'
      }
    ],
    area: [
      {
        title: 'Ventas',
        value: 'Ventas'
      },
      {
        title: 'Administración',
        value: 'Administración'
      },
      {
        title: 'Taller',
        value: 'Taller'
      },
      {
        title: 'Otro',
        value: 'Otro'
      }
    ]
  };

  return (
    <React.Fragment>
      {success && (
        <div className="container">
          <div className="col col-three col-m-twelve" />
          <div className="col col-six col-m-twelve col-center">
            <Check />

            <h2>¡Gracias!</h2>
            <p>
              Hemos recibido tus datos. Para consultas podés llamarnos
              al +54 261 4439300. O visitarnos en San Martín Sur 600. Godoy
              Cruz, Mendoza.
            </p>
          </div>
        </div>
      )}
      {!success && (
        <form action="/cv" method="post" encType="multipart/form-data">
          <div className="container">
            <div className="col col-three col-m-twelve" />
            <div className="col col-six col-m-twelve col-center">
              <br />
              <h2>Se parte del equipo de Territorio Yacopini</h2>
              <p>
                Si querés trabajar con nosotros completa el formulario nos
                pondremos en contacto con vos en los próximos días. Recordá
                adjuntar tu curriculum vitae.
              </p>
            </div>
          </div>
          {error && (
            <div className="container">
              <div className="col col-three col-m-twelve" />
              <div className="col col-six col-m-twelve col-center">
                <h2>Error</h2>
                <p>Por favor adjuntá tu currículum en formato Word o PDF.</p>
              </div>
            </div>
          )}
          <div className="container home__jobs__form">
            <div className="col col-six col-m-twelve">
              <div>
                <Textfield label="Nombre" half name="data[name]" required />
                <Textfield
                  label="Apellido"
                  half
                  name="data[lastname]"
                  required
                />
              </div>
              <div>
                <Textfield
                  label="Teléfono"
                  placeholder="Ej. 261 5 123 456"
                  half
                  name="data[telephone]"
                />
                <Textfield
                  label="Celular"
                  half
                  name="data[cellphone]"
                  required
                />
              </div>
              <div>
                <Textfield label="Email" name="data[email]" required />
              </div>
              <div>
                <Select
                  label="Estudios Completos"
                  list={options.education}
                  half
                  name="data[studies]"
                  handleChange
                />
                <Select
                  label="Puesto"
                  list={options.area}
                  half
                  name="data[position]"
                  handleChange
                />
              </div>

              <div>
                <Textarea label="Comentario / detalle" name="data[message]" />
              </div>

              <div>
                <Select
                  label="Zona de residencia"
                  list={options.location}
                  name="data[zone]"
                  handleChange
                />
              </div>

              <div>
                <Textfield
                  label="Adjunta tu curriculum vitae"
                  file
                  name="file"
                  required
                />
              </div>
              <Button text="Enviar" variant="main" type="subtmit" />
            </div>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};
