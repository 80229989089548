import React from 'react';
import Button from 'components/atoms/button/index.jsx';
import Responsive from 'react-responsive';
import onStarLogo from 'resources/img/onstar_logo.svg';
import './OnStarBanner.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

export default class OnStarBanner extends React.Component {
  render() {
    let className = 'onStarBanner';

    return (
      <div className={className}>
        <Desktop>
          <div className="container">
            <div className="col col-two col-m-twelve">
              <img src={onStarLogo} alt="On Star" height="48" />
            </div>
            <div className="col col-seven col-m-twelve">
              <h4>Vos y tu familia siempre protegidos. </h4>
              <p>Conoce los servicios OnStar incluídos en tu Chevrolet.</p>
            </div>
            <div className="col col-three col-m-twelve">
              <a
                href="https://www.yacopinigm.com.ar/gm-website/files/Catalogo_OnStar_Cliente.pdf"
                target="_blank"
                className="button-link"
              >
                <Button fluid variant="main" text="Descargar Catálogo" />
              </a>
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="container">
            <div>
              <div className="col col-twelve col-center">
                <img src={onStarLogo} alt="On Star" height="48" />
              </div>
              <div className="col col-twelve col-center">
                <p>Con OnStar, vos y tu familia están siempre protegidos</p>
                <br />
              </div>
            </div>
            <div>
              <div className="col col-twelve col-center">
                <a
                  href="https://www.yacopinigm.com.ar/gm-website/files/Catalogo_OnStar_Cliente.pdf"
                  target="_blank"
                  className="button-link"
                >
                  <Button variant="main" text="Descargar Catálogo " />
                </a>
              </div>
            </div>
          </div>
        </Mobile>
      </div>
    );
  }
}
