import React from 'react';
import Button from 'components/atoms/button/index.jsx';
import { display, displayUSD } from 'helpers/price';
import { parseRoute } from 'config/routes';

import './UnitsCard.scss';

export default function UnitsCard({ vehicle, theme, family }) {
  const link = parseRoute('buy', {
    brand: vehicle.brand,
    id: vehicle.id,
    model: vehicle.name,
    family: family.name,
    family_id: family.id
  });

  const displayPrice = vehicle.currency === 'usd' ? displayUSD : display;

  return (
    <div className={`units-card ${theme ? ' units-card-' + theme : ''}`}>
      <h4 className="units-card__name">{vehicle.name.toLowerCase()}</h4>
      <h4 className="units-card__price">
        {vehicle.discount_amount > 0 && (
          <span className="units-card__discount-price">
            {displayPrice(vehicle.price_discount)}*{' '}
            <span className="units-card__old-price">
              {displayPrice(vehicle.price)}
            </span>{' '}
          </span>
        )}
        {!vehicle.discount_amount && (
          <span>{displayPrice(vehicle.price)}* </span>
        )}
      </h4>
      <h5 className="units-card__details">
        {vehicle.doors !== null && <span>{vehicle.doors}.</span>}{' '}
        {vehicle.color !== null && <span>COLOR {vehicle.color}.</span>}{' '}
        {vehicle.location !== null && (
          <span>UBICACIÓN: {vehicle.location}.</span>
        )}
      </h5>
      {vehicle.discount_type !== null && (
        <h5 className="units-card__discount">
          {vehicle.discount_type}: -{displayPrice(vehicle.discount_amount)}
        </h5>
      )}
      <div className="units-card__action">
        <a href={link} target="_blank" rel="noopener noreferrer">
          {getButton(theme)}
        </a>
      </div>
    </div>
  );
};

const getButton = theme => {
  switch (theme) {
    case 'vw':
      return <Button fluid color="blue" variant="main" text="Reservar" />;
    case 'gm':
      return <Button fluid color="yellow" variant="main" text="Reservar" />;
    case 'nissan':
      return <Button fluid color="red" variant="main" text="Reservar" />;
    default:
      return <Button fluid variant="main" text="Reservar" />;
  }
};
