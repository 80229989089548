import { getBrandName } from 'config/brands';

export default class Vehicle {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  set model(model) {
    this.name = model;
  }

  set price(price) {
    this._price = this.parsePrice(price);
  }

  set list_price(list_price) {
    this._list_price = this.parsePrice(list_price);
  }

  set discount(discount) {
    this._discount = this.parsePrice(discount);
  }

  set special_discount(discount) {
    this._special_discount = this.parsePrice(discount);
  }

  set doors(doors) {
    this._doors = doors;
  }

  set color(color) {
    this._color = color;
  }

  set license_cost(price) {
    this.license_price = parseFloat(price);
  }

  set location(location) {
    this._location = location;
  }

  set preparation_cost(price) {
    this.delivery_price = parseFloat(price);
  }

  get brand() {
    return getBrandName(this.brand_id);
  }

  get discount() {
    return this._discount;
  }

  get special_discount() {
    return this._special_discount;
  }

  get price() {
    return this._list_price ? this._list_price : this._price;
  }

  get license_cost() {
    return this.license_price;
  }

  get list_price() {
    return this._list_price;
  }

  get price_discount() {
    return this.price - this.discount_amount;
  }

  get full_price_with_discount() {
    const calculatedPrice =
      this.price_discount +
      this.parsePrice(this.license_price) +
      this.parsePrice(this.delivery_price);

    return calculatedPrice;
  }

  get doors() {
    return this._doors ? this._doors : null;
  }

  get color() {
    return this._color ? this._color : null;
  }

  get location() {
    return this._location ? this._location : null;
  }

  get discount_type() {
    if (this.special_discount > 0) {
      return 'Descuento especial';
    }

    if (this.discount > 0) {
      return 'Descuento web';
    }

    return null;
  }

  get discount_amount() {
    if (this.special_discount > 0) {
      return this.special_discount;
    }

    if (this.discount > 0) {
      return this.discount;
    }

    return null;
  }

  get preparation_cost() {
    return this.delivery_price;
  }

  parsePrice(price = '') {
    if (typeof price !== 'string') {
      return price;
    }

    return parseFloat(price);
  }

  getChassis() {
    return this.chassis || this.chasis;
  }

  getPromotionDiscount(promotionCode = null) {
    const price = this.full_price_with_discount;
    let discount = 0;

    if (promotionCode) {
      if (promotionCode.money_discount) {
        discount = promotionCode.money_discount;
      } else {
        discount = price * (promotionCode.percentage_discount / 100);
      }
    }

    return discount;
  }

  getPromotionPrice(promotionCode = null) {
    return (
      this.full_price_with_discount - this.getPromotionDiscount(promotionCode)
    );
  }
}
