import Brands from 'config/brands';

export const department = {
  SALES: 'sales',
  PLANS: 'plans',
  SERVICES: 'services',
  ADMIN: 'administration'
};

export function getContacts(
  parameters,
  department,
  brandId = Brands.territorio.id
) {
  if (!parameters) {
    return [];
  }

  const methods = parameters.contact_methods || [];
  return methods.reduce((methods, method) => {
    if (method.brand_id === brandId && method.department === department) {
      methods.push(method);
    }

    return methods;
  }, []);
}
