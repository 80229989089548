import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Hero from 'components/organisms/hero/index.jsx';
import Arrowlink from 'components/atoms/arrowLink/index.jsx';
import FlowNavigation from 'components/molecules/flowNavigation/index.jsx';
import FlowHeader from 'components/molecules/flowHeader/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import SummaryCard from 'components/molecules/summaryCard/index.jsx';
import Loader from 'components/atoms/loader';
import Textfield from 'components/molecules/textfield/index.jsx';

import { getHeroImage } from 'helpers/vehicle';
import { parseRoute } from 'config/routes';
import brands from 'config/brands';
import stockActions from 'actions/stock';
import * as dealershipActions from 'actions/dealership';
import {
  checkPromotionCode,
  discoverPromotionCode
} from 'actions/promotionCode';
import { display } from 'helpers/price';

import '../DigitalDealership.scss';


class StartConfirmation extends React.Component {
  state = {
    promotionCode: '',
    addPromotionCode: false
  };

  constructor(props) {
    super(props);

    this.handlePromotionCodeChange = this.handlePromotionCodeChange.bind(this);
  }

  componentDidMount() {
    this.props.reset();

    const { brand, id } = this.props.match.params;

    this.props.fetch({
      brandId: brands[brand].id,
      id
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicle) {
      return;
    }

    const { brand, family } = this.props.match.params;

    if (this.props.vehicle) {
      this.props.discoverPromotions({
        family,
        chassis: this.props.vehicle.getChassis(),
        brand_id: brands[brand].id
      });
    }
  }

  handlePromotionCodeChange(e) {
    if (this.promotionTimeout) {
      clearTimeout(this.promotionTimeout);
    }

    const promotionCode = e.target.value;

    this.setState({ promotionCode });

    this.promotionTimeout = setTimeout(() => {
      this.checkPromotionCode(promotionCode);
    }, 900);
  }

  checkPromotionCode(promotionCode) {
    const { brand, family } = this.props.match.params;

    this.props.checkPromotionCode({
      code: promotionCode,
      family,
      chassis: this.props.vehicle.getChassis(),
      brand_id: brands[brand].id
    });
  }

  render() {
    const {
      vehicle,
      stockError,
      availablePromotion,
      checkingPromotion,
      promotion
    } = this.props;
    const { brand, family, family_id, model } = this.props.match.params;
    const { addPromotionCode, promotionCode } = this.state;

    if (vehicle === undefined) {
      return this.renderLoading();
    }

    if (vehicle === null || stockError) {
      return this.renderError('El vehículo seleccionado no está disponible.');
    }

    const familyHero = getHeroImage({ name: family });

    let bookingPrice = 400000;
    if (this.props.parameter) {
      bookingPrice = this.props.parameter.services[brands[brand].id].booking_price || 400000;
    }

    return (
      <div className="digital-dealership digital-dealership__confirmation">
        <Helmet>
          <title>Concesionario Digital - Territorio Yacopini</title>
        </Helmet>
        <FlowHeader center />
        <Hero type="background" small imageAlt="Concesionario Digital">
          <div className="col col-two col-not-mobile " />
          <div className="col col-eight col-m-twelve col-center col-not-padded ">
            <h2>Concesionario Digital</h2>
            <p>
              Por favor revisá y confirmá que los datos sean correctos antes de
              empezar.
            </p>
          </div>
        </Hero>
        <div className="section section-small">
          <div className="container">
            <div className="col col-two col-not-mobile " />
            <div className="col col-eight col-t-twelve col-center">
              <SummaryCard
                image={familyHero ? familyHero.hero : undefined}
                vehicle={vehicle}
                promotion={promotion}
                dark
                full
                title={brands[brand].name}
                brandId={brands[brand].id}
                noActions
                priceDetail={[
                  {
                    id: 1,
                    name: 'Precio de lista (contado)',
                    price: vehicle.price
                  },
                  {
                    id: 2,
                    name: 'Patentamiento',
                    price: vehicle.license_price
                  },
                  { id: 3, name: 'Entrega', price: vehicle.delivery_price },
                  {
                    id: 4,
                    name: 'Descuento especial',
                    price: -vehicle.discount_amount
                  },
                  { id: 5, name: 'Pago de reserva', text: display(bookingPrice) }
                ]}
              >
                <p className="p-s">
                  Color: {vehicle.color}
                  <br />
                  {vehicle.location ? `Ubicación: ${vehicle.location}` : null}
                  <br />
                  Número de Chasis: {vehicle.getChassis()}
                </p>

                {promotion && (
                  <p className="p-s">Código de descuento {promotion.code}</p>
                )}

                {!promotion && addPromotionCode && (
                  <div>
                    <Textfield
                      label="Código de descuento"
                      half
                      value={promotionCode}
                      name="promotionCode"
                      onChange={this.handlePromotionCodeChange}
                      autoFocus
                    />
                  </div>
                )}

                {!promotion && !addPromotionCode && (
                  <p>
                    <Button
                      text="Agregar Descuento"
                      small
                      variant="main"
                      onClick={() => this.setState({ addPromotionCode: true })}
                    ></Button>
                  </p>
                )}
                {!promotion && !addPromotionCode && availablePromotion && (
                  <p>
                    <Arrowlink
                      text={`Agregar Descuento ${availablePromotion.code}`}
                      small
                      onClick={() =>
                        this.checkPromotionCode(availablePromotion.code)
                      }
                    />
                  </p>
                )}

                {checkingPromotion && <Loader />}
              </SummaryCard>
              <p className="p p-s">
                Para finalizar el proceso de reserva deberás abonar {display(bookingPrice)} con
                tu tarjeta de credito (Visa, MasterCard o Naranja) en 1, 2 o 3
                cuotas.
              </p>
            </div>
          </div>
        </div>

        <FlowNavigation>
          <Link
            to={parseRoute('dealership_start', {
              id: vehicle.id,
              brand,
              family,
              family_id,
              model
            })}
          >
            <Button text="Reservar online" variant="main" type="button" />
          </Link>
        </FlowNavigation>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="digital-dealership digital-dealership__start">
        <FlowHeader center />
        <Loader />
      </div>
    );
  }

  renderError(message) {
    return (
      <div className="digital-dealership digital-dealership__start">
        <FlowHeader center />
        <Hero
          type="background"
          big
          smallMobile
          imageAlt="Concesionario Digital"
        >
          <div className="col col-three col-not-mobile " />
          <div className="col col-six col-m-twelve col-center ">
            <h2>Concesionario Digital</h2>
            <div className="p p-b">{message}</div>
            <br />
            <Link to="/">Volver</Link>
          </div>
        </Hero>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    vehicle: state.stock.selected,
    stockError: state.stock.error,
    availablePromotion: state.promotionCode.availablePromotion,
    checkingPromotion: state.promotionCode.checkingPromotion,
    promotion: state.promotionCode.promotion,
    parameter: state.parameter.all
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: ({ brandId, id }) => {
      const query = brandId === 4 || brandId === 6 ? false : true;

      dispatch(stockActions.fetchOne({ id, brandId, query }));
    },
    reset: () => dispatch(dealershipActions.reset()),
    discoverPromotions: data => dispatch(discoverPromotionCode(data)),
    checkPromotionCode: data => dispatch(checkPromotionCode(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartConfirmation);
