import React from 'react';
import './ProgressBar.scss';

export default class ProgressBar extends React.Component {
  render() {
    const { active, steps, modal } = this.props;

    let className = 'progress-bar' + (modal ? ' progress-bar-modal' : '');
    const listSteps = steps.map(index => (
      <div
        key={index.id}
        className={
          'progress-bar__step' +
          (active === index.id ? ' progress-bar__step-active' : '') +
          (active > index.id ? ' progress-bar__step-completed' : '')
        }
      >
        <a className="progress-bar__step__link" href={index.link}>
          {index.name}
        </a>
        <div className="progress-bar__step__number">
          <span>{index.id}</span>
        </div>
        <div className="progress-bar__step__name">{index.name}</div>
      </div>
    ));

    return (
      <div className={className}>
        <div className="progress-bar__steps">{listSteps}</div>
      </div>
    );
  }
}
