import React from 'react';
import { display as displayPrice, displayUSD } from 'helpers/price';
import Arrowlink from 'components/atoms/arrowLink/index.jsx';

import './VersionCard.scss';

export default class VersionCard extends React.Component {
  render() {
    const {
      model,
      version,
      brand,
      price,
      image,
      link,
      disabled,
      features,
      open,
      compare,
      onClick,
      currency
    } = this.props;

    let className =
      'version-card' +
      (disabled ? ' version-card-disabled' : '') +
      (open ? ' version-card-open' : '') +
      (compare ? ' version-card-compare' : '');

    var unitsMessage;
    if (disabled) {
      unitsMessage = (
        <div className="version-card__units-info">
          <div className="version-card__nounits">
            No tenemos disponibilidad de esta versión.
          </div>
          <div className="version-card__arrowlink">
            Contactate con un asesor
          </div>
        </div>
      );
    } else if (price) {
      unitsMessage = (
        <div className="version-card__units-info">
          <div className="version-card__arrowlink">
            Ver unidades disponibles
          </div>
        </div>
      );
    } else {
      unitsMessage = (
        <div className="version-card__units-info">
          <div className="version-card__arrowlink">Consultar precios</div>
        </div>
      );
    }
    var brandElement;
    if (brand) {
      brandElement = <span>{brand}</span>;
    }

    return (
      <div className={className}>
        <a href={link} onClick={onClick} className="version-card__link">
          Ver unidades disponibles
        </a>

        <h5 className="version-card__model">
          {brandElement} {model}
        </h5>
        <h3 className="version-card__version">{version.toLowerCase()}</h3>
        <div className="version-card__image">
          <img src={image} alt={model} />
        </div>
        <div className="version-card__price">
          {price !== null && <span>Desde {currency === 'usd' ? displayUSD(price) : displayPrice(price)}</span>}
        </div>
        <div className="version-card__characteristics">
          {features &&
            features.map((feature, id) => (
              <div key={id} className={'version-card__characteristic'}>
                {feature}
              </div>
            ))}
        </div>
        <div className="version-card__download-characteristic">
          <Arrowlink text="Descargar ficha técnica" small />
        </div>
        {unitsMessage}
      </div>
    );
  }
}
