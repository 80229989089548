import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Responsive from 'react-responsive';
import Button from 'components/atoms/button/index.jsx';
import MobileHeader from 'components/organisms/mobileHeader/index.jsx';
import Header from 'components/organisms/header/index.jsx';
import Footer from 'components/organisms/footer/index.jsx';
import '../News.scss';

const Mobile = props => <Responsive {...props} maxWidth={1151} />;
const DesktopHeader = props => <Responsive {...props} minWidth={1024} />;


class Post extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if (!this.props.parameters) {
      return <div className="news" />
    };

    const id = parseInt(this.props.match.params.id);
    const news = this.props.parameters.news.find(news => news.id === id);
    const nextNews = this.props.parameters.news.reduce((next, current, i) => {
      if (current.id === id) {
        return this.props.parameters.news[i+1];
      }

      return next;
    }, null);

    return (
      <div className="news">
        <Helmet>
          <title>Territorio Yacopini - {news.title}</title>
          <meta
            name="description"
            content={news.title}
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>
        <DesktopHeader>
          <Header active={0} />
        </DesktopHeader>
        <Mobile>
          <MobileHeader title="Noticias" type="back" link="/noticias" />
        </Mobile>   
          <div className="section section-center section-white section-relative news__post">
            <div className="container">
              <div className="col col-three  col-not-mobile">
              <Link to="/noticias"><Button text="Volver a noticias"></Button> </Link>
              </div>
              <div className="col col-six col-m-twelve col-not-padded">
                <div className='news__item'>
                  {news ? (
                    <>
                      <h5>{news.type_string}. {news.brand_string}.</h5>
                      <h2>{news.title}</h2>
                      <p><strong>{news.display_date}</strong></p>
                      <div dangerouslySetInnerHTML={{ __html: news.content }} />
                      {news.images.map(image => (<img src={image.path} width="100%" alt="Imagen de la noticia" />))}
                      {news.url && <p><a href={news.url} target="_blank" rel="noopener noreferrer">Ver link de la noticia.</a></p>}
                    </>
                  ) : (
                    <>
                      <h2>Noticia no encontrada.</h2>
                      <Link to="/noticias">Volver</Link>
                    </>
                  )}
                </div>
              </div>
              <div className="col col-three col-not-mobile"></div>
            </div>
          </div>
          {nextNews && (
            <div className=" section section-center  section-relative ">
              <div className="container">
                <div className="col col-three col-not-mobile"></div>
                <div className="col col-six col-m-twelve col-not-padded">
                  <div className='news__item'>
                    <h5>Siguiente:</h5>
                    {nextNews.url ? (
                      <a href={nextNews.url} target="_blank" rel="noopener noreferrer"><h3>{news.title}</h3></a>
                    ) : (
                      <Link to={nextNews.slug_url}><h3>{news.title}</h3></Link>
                    )}
                  </div>
                </div>
                <div className="col col-three col-not-mobile"></div>
              </div>
            </div>
          )}
        <Footer alt />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    parameters: state.parameter.all
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
