export default class Service {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  getPrice(legacyService) {
    if (this.price_promotion > 0) {
      return this.price_promotion;
    }

    return legacyService ? this.price_premium : this.price_default;
  }
}
