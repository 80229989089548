import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Responsive from 'react-responsive';
import ModalHeader from 'components/organisms/modalHeader/index.jsx';
import MobileHeader from 'components/organisms/mobileHeader/index.jsx';
import MobileActions from 'components/organisms/mobileActions/index.jsx';
import Button from 'components/atoms/button'

import './StoriesModal.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

class StoriesModal extends React.Component {
  render() {
    const { stories } = this.props;

    if (!stories) {
      return null;
    }

    const id = parseInt(this.props.match.params.id);
    const story = stories.find(story => story.id === id)
    const index = stories.indexOf(story);
    const nextStory = stories[index+1];

    if (!story) {
      return window.location.replace('/');
    }

    const onClose = () => {
      let backUrl = '/';

      switch (story.category_string) {
        case 'Planes de Ahorro':
          backUrl = '/planes-de-ahorro';
          break;
        case '0 km':
          backUrl = '/vehiculos-0km';
          break;
        case 'Postventa':
          backUrl = '/postventa';
          break;
        default:
      }

      this.props.history.push(backUrl);
    };

    return (
      <div className="stories-modal" key={story.id}>
        <Desktop>
          <ModalHeader dark title="Historias Yacopini" type="back" onClose={onClose} />
        </Desktop>
        <Mobile>
          <MobileHeader dark title="Historias Yacopini" type="back" onClose={onClose} />
          {nextStory && (
            <Link to={nextStory.slug_url}>
              <MobileActions
                variant="main"
                text="Siguiente Historia"
                transparent
              />
            </Link>
          )}
        </Mobile>

        <Desktop>
          <div className=" stories__content">
            <div className='story-full'>
                <div className='story-full-data'>
                    {nextStory && (
                      <div className='story-full-data-actions'>
                        <Link to={nextStory.slug_url}>
                          <Button text="Siguiente historia" variant="main" />
                        </Link>
                      </div>
                    )}
                    <div className='story-full-data-content'>
                      <h4>{story.title}</h4>
                      <h5>{story.brand_string} / {story.category_string}</h5>
                    </div>
                </div>
                  <div className='story-full-video-preview'>
                    <video autoPlay onClick={event => event.target.play()} loop > 
                      <source src={`https://territorioyacopini.com.ar/${story.video_path}`} type="video/mp4"/>
                    </video>  
                  </div>   
              </div>
          </div>
        </Desktop>

        <Mobile>
          <div className="stories__content">
            <div className='story-full'>
              <div className='story-full-data'>
                {nextStory && (
                  <div className='story-full-data-actions'>
                    <Link to={nextStory.slug_url}>
                      <Button text="Siguiente historia" variant="main" />
                    </Link>
                  </div>
                )}
                <div className='story-full-data-content'>
                  <h4>{story.title}</h4>
                  <h5>{story.brand_string} / {story.category_string}</h5>
                </div>
              </div>
                <div className='story-full-video-preview'>
                  <video autoPlay onClick={event => event.target.play()} loop > 
                    <source src={`https://territorioyacopini.com.ar/${story.video_path}`} type="video/mp4"/>
                  </video>  
                </div>   
            </div>
          </div>
        </Mobile>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    stories: state.parameter.all?.stories
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StoriesModal);
