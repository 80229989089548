import React from 'react';
import { Link } from 'react-router-dom';
import './MobileActions.scss';
import Button from 'components/atoms/button/index.jsx';

export default class MobileActions extends React.Component {
  render() {
    const { variant, back, link, text, onClick, transparent } = this.props;

    

    let className = 'mobile-actions' +
      (transparent ? ' mobile-actions-transparent' : '') ;

    var backElement;
    if (back) {
      backElement = <Button text="Volver" variant="ghost" back />;
    }

    return (
      <div className={className}>
        <div className="mobile-actions__actions">
          {link && (
            <Link to={link}>
              <Button text={text} variant={variant} />
            </Link>
          )}
          {onClick && (
            <Button text={text} variant={variant} onClick={onClick} />
          )}
          {backElement}
        </div>
      </div>
    );
  }
}
