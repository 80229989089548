import React from 'react';
import PropTypes from 'prop-types';
import arrowOrange from 'resources/img/arrow-link.svg';
import arrowBlue from 'resources/img/arrow-link-blue.svg';
import arrowYellow from 'resources/img/arrow-link-yellow.svg';
import arrowWhite from 'resources/img/arrow-link-white.svg';
import arrowBackWhite from 'resources/img/arrow-link-white-back.svg';
import arrowBack from 'resources/img/arrow-link-back.svg';
import './Arrowlink.scss';

export default class Arrowlink extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const {
      variant,
      color,
      type,
      text,
      small,
      fluid,
      disabled,
      back
    } = this.props;

    let className =
      'arrowlink ' +
      (variant ? ' arrowlink-' + variant : '') +
      (color ? ' arrowlink-' + color : '') +
      (fluid ? ' arrowlink-fluid' : '') +
      (back ? ' arrowlink-back' : '') +
      (disabled ? ' arrowlink-disabled' : '') +
      (small ? ' arrowlink-small' : '');

    //IMAGE FOR ARROW COLOR
    var arrowElement;
    if (!color && !back) {
      arrowElement = (
        <span className="arrowlink__arrow">
          <img src={arrowOrange} alt={text} />
        </span>
      );
    } else {
      if (!back) {
        if (color === 'blue') {
          arrowElement = (
            <span className="arrowlink__arrow">
              <img src={arrowBlue} alt={text} />
            </span>
          );
        }
        if (color === 'yellow') {
          arrowElement = (
            <span className="arrowlink__arrow">
              <img src={arrowYellow} alt={text} />
            </span>
          );
        }
        if (color === 'white') {
          arrowElement = (
            <span className="arrowlink__arrow">
              <img src={arrowWhite} alt={text} />
            </span>
          );
        }
      } else {
        if (color === 'white') {
          arrowElement = (
            <span className="arrowlink__arrow">
              <img src={arrowBackWhite} alt={text} />
            </span>
          );
        } else {
          arrowElement = (
            <span className="arrowlink__arrow">
              <img src={arrowBack} alt={text} />
            </span>
          );
        }
      }
    }

    return (
      <button
        className={className}
        onClick={this.handleOnClick}
        type={type || 'button'}
        disabled={disabled}
      >
        {text}
        {arrowElement}
      </button>
    );
  }
}

Arrowlink.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string
};
