import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Responsive from 'react-responsive';
import { Link } from 'react-router-dom';
import OfferCard from 'components/molecules/offerCard/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import Header from 'components/organisms/header/index.jsx';
import MobileNavigation from 'components/organisms/mobileNavigation/index.jsx';
import Footer from 'components/organisms/footer/index.jsx';
import Hero from 'components/organisms/hero/index.jsx';
import Tabs from 'components/molecules/tabs/index.jsx';
import HowToBuyBanner from 'components/molecules/howToBuyBanner/index.jsx';
import OnStarBanner from 'components/molecules/onStarBanner/index.jsx';
//import StickyNav from 'components/molecules/stickyNav/index.jsx';
import ModelCard from 'components/molecules/modelCard/index.jsx';
import Arrowlink from 'components/atoms/arrowLink/index.jsx';
import MobileTabs from 'components/molecules/mobileTabs/index.jsx';
import StoriesBlock from 'components/organisms/storiesBlock';
import Loader from 'components/atoms/loader';
import {
  switchToVolkswagen,
  switchToChevrolet,
  switchToNissan,
  switchToBAIC,
} from 'actions/brand';
import familyActions from 'actions/family';
import savingPlanActions from 'actions/savingPlan';
import vwHero from 'resources/img/vw/vw_models_hero@2x.jpg';
import gmHero from 'resources/img/gm/gm_models_hero@2x.jpg';
import nissanHero from 'resources/img/nissan/nissan_models_hero@2x.jpg';
import baicHero from 'resources/img/baic/hero.png';
import brands from 'config/brands';
import { parseRoute } from 'config/routes';

import './Models.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;
const DesktopHeader = props => <Responsive {...props} minWidth={1024} />;
const MobileHeader = props => <Responsive {...props} maxWidth={1023} />;

class Models extends React.Component {
  state = {};
  getActiveBrandIndex() {
    switch (this.props.brandId) {
      case brands.chevrolet.id:
        return 1;
      case brands.nissan.id:
        return 2;
      case brands.baic.id:
        return 3;
      case brands.volkswagen.id:
        return 0;
      default:
        return null;
    }
  }

  getHero() {
    switch (this.props.brandId) {
      case brands.chevrolet.id:
        return gmHero;
      case brands.nissan.id:
        return nissanHero;
      case brands.baic.id:
        return baicHero;
      case brands.volkswagen.id:
      default:
        return vwHero;
    }
  }

  componentDidMount() {
    const {
      brandName,
      match: {
        params: { brand }
      }
    } = this.props;

    if (brand === brandName) {
      return;
    }

    switch (brand) {
      case 'volkswagen':
        this.props.switchToVolkswagen();
        break;
      case 'chevrolet':
        this.props.switchToChevrolet();
        break;
      case 'nissan':
        this.props.switchToNissan();
        break;
      case 'baic':
        this.props.switchToBAIC();
        break;
      default:
        break;
    }
  }

  render() {
    const {
      brandName,
      savingsPlanName,
      families,
      theme,
      brandId,
      offersByBrand,
      fetchingOffers
    } = this.props;
    const offers = offersByBrand[brandId].best.slice(0, 4);

    const tabs = [
      {
        name: 'Volkswagen',
        link: parseRoute('family', { brand: 'volkswagen' }),
        onClick: () => this.props.switchToVolkswagen()
      },
      {
        name: 'Chevrolet',
        link: parseRoute('family', { brand: 'chevrolet' }),
        onClick: () => this.props.switchToChevrolet()
      },
      {
        name: 'Nissan',
        link: parseRoute('family', { brand: 'nissan' }),
        onClick: () => this.props.switchToNissan()
      },
      {
        name: 'BAIC',
        link: parseRoute('family', { brand: 'baic' }),
        onClick: () => this.props.switchToBAIC()
      },
      {
        name: 'Ducati',
        link: parseRoute('ducati')
      }
    ];

    const stories = this.props.parameters?.stories ? this.props.parameters.stories.filter(story => story.category_string === '0 km') : [];

    return (
      <div className="models">
        <Helmet>
          <title>Vehículos 0km {brandName}</title>
          <meta
            name="description"
            content="Territorio Yacopini - Concesionario oficial Volkswagen, Chevrolet, Nissan y Ducati"
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>

        <DesktopHeader>
          <Header active={1} />
        </DesktopHeader>
        <MobileHeader>
          <MobileTabs title="Nuestras Marcas" tabs={tabs} active={this.getActiveBrandIndex()} />
        </MobileHeader>
        <MobileHeader>
          <MobileNavigation active={1} />
        </MobileHeader>
        <DesktopHeader>
          <div className=" section section-small section-center">
            <div className="container ">
              <div className="col col-twelve">
                <Tabs
                  title="Nuestras marcas"
                  tabs={tabs}
                  active={this.getActiveBrandIndex()}
                />
              </div>
            </div>
          </div>
        </DesktopHeader>
        <Hero type="image" big image={this.getHero()} imageAlt="Volkswagen">
          <div className="col col-five col-m-twelve col-sd-six">
            <h1>{brandName}</h1>
            <p className="p-b">
              Toda la familia {brandName} a tu alcance. Más diseño, más
              tecnología y por supuesto, más confort.
              {savingsPlanName &&
                ` Ademas con ${savingsPlanName}, tenes la mejor financiación para tu 0km.`}
            </p>
            <Button
              text="Buscá el tuyo"
              variant="main"
              color={theme}
              onClick={() => {
                this.modelsSection.scrollIntoView();
              }}
            />
          </div>
        </Hero>
        <StoriesBlock title="0 km" stories={stories} /> 
        {brandName === 'Chevrolet' && <OnStarBanner />}
        <Desktop>
          <div
            className={`background ${theme} gradient section section-big models__offers`}
          >
            <div className="container ">
              <div className="col col-eight ">
                <h2>Oportunidades {brandName}</h2>
              </div>
              <div className="col col-four col-right">
                <Link to="/oportunidades">
                  <Arrowlink color="white" text="Ver todas las oportunidades" />
                </Link>
              </div>
            </div>
            <div className="container">
              {offers.map(offer => (
                <div className="col col-three" key={offer.id}>
                  <OfferCard offer={offer} small />
                </div>
              ))}
              {fetchingOffers && <Loader />}
            </div>
          </div>
        </Desktop>

        <Mobile>
          <div
            className={`background ${theme} gradient section section-big models__offers`}
          >
            <div className="container ">
              <div className="col col-twelve">
                <h2>Oportunidades {brandName}</h2>
              </div>

              {offers.map(offer => (
                <div className="col col-m-twelve" key={offer.id}>
                  <OfferCard offer={offer} small />
                </div>
              ))}

              <div className="col col-twelve">
                <Link to="/oportunidades">
                  <Arrowlink color="white" text="Ver todas las oportunidades" />
                </Link>
              </div>
            </div>
          </div>
        </Mobile>
        <HowToBuyBanner />

        <div
          className=" section section-center section-white section-relative models__models-list"
          ref={section => {
            this.modelsSection = section;
          }}
        >
          {/* <StickyNav
            sections={[
              { id: 1, name: 'Todos', link: '#all' },
              { id: 2, name: 'Sedanes', link: '#sedanes' },
              { id: 3, name: 'Compactos', link: '#compactos' },
              { id: 4, name: 'Monovolumenes, SUVs y Pickups', link: '#other' }
            ]}
            active={1}
          />*/}
          <div className="container ">
            <div className="col col-six col-m-twelve">
              <h1>Todos nuestros {brandName}</h1>
            </div>
          </div>
        </div>
        <div className=" section section-center section-white" id="sedanes">
          {/* <div className="container ">
            <div className="col col-six col-m-twelve">
              <h5>Sedanes</h5>
            </div>
          </div>*/}
          <div className="container ">
            {families.length === 0 && <Loader />}
            {families.map(
              (family, i) =>
                family && (
                  <div className="col col-three col-m-twelve" key={`${family.id}-${i}`}>
                    <ModelCard
                      model={family}
                      brandId={brandId}
                      link={parseRoute('subfamilies', {
                        brand: brandName,
                        family: family.name,
                        id: family.id
                      })}
                      linkLabel="Explorar versiones"
                    />
                  </div>
                )
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    brandId: state.brand.active,
    brandName: state.brand.name,
    savingsPlanName: state.brand.savingsPlanName,
    families: state.family.all,
    theme: state.brand.theme,
    offersByBrand: state.offer.byBrand,
    fetchingOffers: state.offer.requesting,
    parameters: state.parameter.all,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    switchToVolkswagen: () => {
      dispatch(switchToVolkswagen());
      dispatch(familyActions.fetchAll());
      dispatch(savingPlanActions.fetchAll());
    },
    switchToChevrolet: () => {
      dispatch(switchToChevrolet());
      dispatch(familyActions.fetchAll());
      dispatch(savingPlanActions.fetchAll());
    },
    switchToNissan: () => {
      dispatch(switchToNissan());
      dispatch(familyActions.fetchAll());
      dispatch(savingPlanActions.reset());
    },
    switchToBAIC: () => {
      dispatch(switchToBAIC());
      dispatch(familyActions.fetchAll());
      dispatch(savingPlanActions.reset());
    },
    fetchFamilies: () => dispatch(familyActions.fetchAll())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Models);
