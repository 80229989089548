import React from 'react';
import { Link } from 'react-router-dom';
import { display, displayUSD } from 'helpers/price';

import './PromotionCard.scss';

export default function OfferGroupCard({ link, small, family, offers, onClick }) {
  let className =
    'offer-group-card' +
    ' promotion-card' +
    (small ? ' promotion-card-small' : '');

  const bestOffer = getBestOffer(offers);

  // If a family is wrongly removed from the backend, we receive undefined
  if (!family) {
    return null;
  }

  const displayPrice = bestOffer.currency === 'usd' ? displayUSD : display;

  return (
    <div className={className} onClick={onClick}>
      {link && <Link to={link} className="promotion-card__link" />}
      <div className="promotion-card__content">
        <h5 className="promotion-card__brand">{bestOffer.brand}</h5>
        <h3 className="promotion-card__model">{family.name.toLowerCase()}</h3>

        <div className="promotion-card__image">
          <img src={family.image} alt={family.name} />
        </div>
        <div className="promotion-card__discount">
          <div className="promotion-card__discount-message">
            Descuento especial de hasta{' '}
            <span className="promotion-card__discount-message__price">
              -{displayPrice(bestOffer.discount_amount)}
            </span>
          </div>
        </div>
      </div>
      <div className="promotion-card__action">
        <div className="promotion-card__units-info">
          Ver {offers.length} unidad{offers.length > 1 ? 'es' : ''}
        </div>
      </div>
    </div>
  );
};

function getBestOffer(offers) {
  let best = offers[0];

  offers.forEach(offer => {
    if (offer.discount_amount > best.discount_amount) {
      best = offer;
    }
  });

  return best;
}
