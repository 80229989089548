import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Responsive from 'react-responsive';

import Arrowlink from 'components/atoms/arrowLink/index.jsx';
import FlowProgressBar from 'containers/digitalDealership/FlowProgressBar.jsx';
import FlowNavigation from 'components/molecules/flowNavigation/index.jsx';
import FlowHeader from 'components/molecules/flowHeader/index.jsx';

import Button from 'components/atoms/button/index.jsx';
import Card from 'components/atoms/card/index.jsx';
import SummaryCard from 'components/molecules/summaryCard/index.jsx';
import BalanceInformation from 'components/molecules/balanceInformation/index.jsx';

import { parseRoute } from 'config/routes';
import { getHeroImage } from 'helpers/vehicle';
import { display as displayPrice } from 'helpers/price';

import '../DigitalDealership.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

class Review extends React.Component {
  render() {
    const { vehicle, operation } = this.props;
    const { operation_code } = this.props.match.params;

    if (!operation || !vehicle) {
      return (
        <Redirect
          to={parseRoute('dealership_resume', {
            operation_code: operation_code
          })}
        />
      );
    }

    const familyHero = getHeroImage({ name: operation.family });

    let bookingPrice = 200000;
    if (this.props.parameter) {
      bookingPrice = this.props.parameter.services[operation.brand_id].booking_price || 200000;
    }

    return (
      <div className="digital-dealership digital-dealership__review">
        <FlowHeader
          small
          title="Concesionario Digital"
          subtitle={`Reservando ${vehicle.name}`}
        />

        <BalanceInformation
          operation={operation}
          cashAmount={operation.cash_amount}
          vehicle={vehicle}
        />

        <div className="section">
          <div className="container">
            <div className="col col-two col-not-mobile " />
            <div className="col col-eight col-m-twelve ">
              <h5>Compra Online</h5>
              <h2>3. Revisa y confirma</h2>
              <Desktop>
                <p className="p">
                  Asegurate de que todos los datos sean correctos para evitar
                  demoras. Luego procederás al pago de $30.000 como seña para la
                  reserva.
                </p>
              </Desktop>
              <Mobile>Asegurate de que todos los datos sean correctos.</Mobile>
            </div>
          </div>
        </div>
        <div className="section section-small">
          <div className="container">
            <div className="col col-two col-not-mobile " />
            <div className="col col-eight col-m-twelve col-not-padded">
              <SummaryCard
                image={familyHero ? familyHero.hero : undefined}
                vehicle={vehicle}
                dark
                full
                title={operation.purchase.email}
                brandId={operation.brand_id}
                noActions
                promotion={operation.promotion}
                priceDetail={[
                  {
                    id: 1,
                    name: 'Precio de lista (contado) ',
                    price: vehicle.price
                  },
                  {
                    id: 2,
                    name: 'Patentamiento',
                    price: vehicle.license_price
                  },
                  { id: 3, name: 'Entrega', price: vehicle.delivery_price },
                  {
                    id: 4,
                    name: 'Descuento especial',
                    price: -vehicle.discount_amount
                  },
                  { id: 5, name: 'Pago de reserva', price: bookingPrice }
                ]}
              >
                <p className="p-s">
                  Color: {vehicle.color}
                  <br />
                  {vehicle.location ? `Ubicación: ${vehicle.location}.` : null}
                  <br />
                  Número de Chasis: {vehicle.getChassis()}
                </p>
              </SummaryCard>
            </div>
          </div>
          <div className="container">
            <div className="col col-two col-not-mobile " />
            <div className="col col-eight col-m-twelve col-not-padded">
              <Card>
                <h4>Modo de pago</h4>
                <p className="p p-s">
                  {operation.cash_amount > 0 && (
                    <>
                      <strong>Efectivo:</strong>{' '}
                      {displayPrice(operation.cash_amount)}
                      <br />
                    </>
                  )}
                  {operation.credit_name && (
                    <>
                      <strong>Financiación:</strong>{' '}
                      {displayPrice(operation.credit_amount)}
                      <br />
                    </>
                  )}
                  {operation.used_vehicles.map((usedVehicle, i) => (
                    <span key={i}>
                      <strong>Vehículo usado:</strong> {usedVehicle.brand}{' '}
                      {usedVehicle.vehicle}{' '}
                      {usedVehicle.valuation > 0 &&
                        displayPrice(usedVehicle.valuation)}
                      {' (sujeto a tasación)'}
                      <br />
                    </span>
                  ))}
                </p>
                <Link
                  to={parseRoute('dealership_payment_selection', {
                    operation_code
                  })}
                >
                  <Arrowlink text="Editar" small />
                </Link>
              </Card>
            </div>
          </div>
          <br />
          <div className="container">
            <div className="col col-two col-not-mobile " />
            <div className="col col-eight col-m-twelve col-not-padded">
              <Card>
                <h4>Datos Personales</h4>
                <p className="p p-s">
                  <strong>Nombre y Apellido:</strong> {operation.purchase.name}{' '}
                  {operation.purchase.lastname} <br />
                  <strong>DNI:</strong> {operation.purchase.dni} <br />
                  <strong>Email:</strong> {operation.purchase.email} <br />
                  <strong>Teléfono:</strong> {operation.purchase.telephone}{' '}
                  <br />
                </p>
                <Link
                  to={parseRoute('dealership_personal_information', {
                    operation_code
                  })}
                >
                  <Arrowlink text="Editar" small />
                </Link>
              </Card>
            </div>
          </div>
        </div>

        <FlowNavigation progressBar={<FlowProgressBar step={3} />}>
          <Link to={parseRoute('dealership_payment', { operation_code })}>
            <Button text="Continuar" variant="main" />
          </Link>
          <Link
            to={parseRoute('dealership_personal_information', {
              operation_code
            })}
          >
            <Button text="Volver" back />
          </Link>
        </FlowNavigation>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    operation: state.dealership.operation,
    vehicle: state.stock.selected,
    parameter: state.parameter.all,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
