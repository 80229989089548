import React from 'react';
import { display, displayUSD } from 'helpers/price';
import './BalanceInformation.scss';

export default function BalanceInformation({ operation, vehicle, cashAmount }) {
  const balanceClassName =
    operation.pending_amount > 0 && !operation.used_vehicles.length
      ? 'balance-information__balance-negative'
      : 'balance-information__balance-positive';

  const displayPrice = vehicle.currency === 'usd' ? displayUSD : display;

  return (
    <div className="balance-information">
      <div className="balance-information__row balance-information-notmobile">
        <div className="balance-information__row__user">
          {operation.purchase.email}
        </div>
      </div>
      <div className="balance-information__row">
        <div className="balance-information__column">Precio Final</div>
        <div className="balance-information__column">
          <span className="balance-information__balance">
            {displayPrice(operation.getTotalAmount(vehicle))}
          </span>
        </div>
      </div>
      <div className="balance-information__row">
        <div className="balance-information__column">Saldo Pendiente</div>
        <div className="balance-information__column">
          <span className={`balance-information__balance ${balanceClassName}`}>
            {displayPrice(
              operation.getPendingAmount(vehicle, { cash_amount: cashAmount })
            )}
          </span>
        </div>
      </div>
      {operation.promotion && (
        <div className="balance-information__row balance-information-notmobile">
          <div className="balance-information__column">Código de promoción</div>
          <div className="balance-information__column">
            <span className="balance-information__balance">
              -{' '}
              {displayPrice(vehicle.getPromotionDiscount(operation.promotion))}
            </span>
          </div>
        </div>
      )}
      {operation.cash_amount > 0 && (
        <div className="balance-information__row balance-information-notmobile">
          <div className="balance-information__column">Efectivo</div>
          <div className="balance-information__column">
            <span className="balance-information__balance">
              {displayPrice(operation.cash_amount)}
            </span>
          </div>
        </div>
      )}
      {operation.credit_id > 0 && (
        <div className="balance-information__row balance-information-notmobile">
          <div className="balance-information__column">Financiación</div>
          <div className="balance-information__column">
            <span className="balance-information__balance">
              {displayPrice(operation.credit_amount)}
            </span>
          </div>
        </div>
      )}
      {operation.used_vehicles.map((usedVehicle, i) => (
        <div
          className="balance-information__row balance-information-notmobile"
          key={i}
        >
          <div className="balance-information__column">Vehículo usado</div>
          <div className="balance-information__column">
            <span className="balance-information__balance">
              {usedVehicle.valuation > 0 && displayPrice(usedVehicle.valuation)}
              {usedVehicle.valuation === 0 && usedVehicle.vehicle}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
