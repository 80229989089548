import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Hero from 'components/organisms/hero/index.jsx';
import Arrowlink from 'components/atoms/arrowLink/index.jsx';
import FlowHeader from 'components/molecules/flowHeader/index.jsx';

import '../DigitalDealership.scss';

class Congrats extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="digital-dealership digital-dealership__start">
        <Helmet>
          <title>Concesionario Digital - Territorio Yacopini</title>
          <meta
            name="description"
            content="Territorio Yacopini - Concesionario oficial Volkswagen, Chevrolet, Nissan y Ducati"
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>
        <FlowHeader center />
        <Hero
          type="background"
          big
          smallMobile
          imageAlt="Concesionario Digital"
        >
          <div className="col col-three col-not-mobile " />
          <div className="col col-six col-m-twelve col-center ">
            <h2>Tu 0km te espera</h2>

            <p className="p p-b">
              El pago se ha realizado con éxito. Acercate por nuestras oficinas
              en las próximas 48 horas para finalizar la compra. Te enviamos un
              email con todos los datos.
            </p>
            <Link to="/">
              <Arrowlink text="Volver a Territorio Yacopini" />
            </Link>
          </div>
        </Hero>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Congrats);
