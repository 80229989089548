import React from 'react';
import './Breadcrumbs.scss';

export default class Breadcrumbs extends React.Component {
  render() {
    const { sections, dark } = this.props;

    let className = 'breadcrumbs' + (dark ? ' breadcrumbs-dark' : '');
    const listSections = sections.map(index => (
      <div className={'breadcrumbs__section'} key={index.id}>
        <a href={index.link}>{index.name}</a>{' '}
        <span className="breadcrumbs__section__spacer"> /</span>
      </div>
    ));

    return (
      <div className={className}>
        <div className="breadcrumbs__container">{listSections}</div>
      </div>
    );
  }
}
