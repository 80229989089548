import React from 'react';
import PropTypes from 'prop-types';
import arrowBack from 'resources/img/back-arrow.svg';
import './Button.scss';

export default class Button extends React.Component {
  render() {
    const {
      variant,
      color,
      type,
      text,
      small,
      fluid,
      disabled,
      spaced,
      back,
      onClick
    } = this.props;

    let className =
      'button ' +
      (variant ? ' button-' + variant : '') +
      (color ? ' button-' + color : '') +
      (fluid ? ' button-fluid' : '') +
      (disabled ? ' button-disabled' : '') +
      (spaced ? ' button-spaced' : '') +
      (back ? ' button-back' : '') +
      (small ? ' button-small' : '');

    var backElement;
    if (back) {
      backElement = (
        <span className="button__arrow">
          <img src={arrowBack} alt={text} />
        </span>
      );
    }

    return (
      <button
        className={className}
        onClick={onClick}
        type={type || 'button'}
        disabled={disabled}
      >
        {backElement}
        {text}
      </button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string
};
