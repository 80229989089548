import React, { useState, useEffect } from 'react';
import './MobileTabs.scss';

const MobileTabs = ({ tabs, title, bottom, inSection, ...props }) => {
  const [active, setActive] = useState(props.active ?? 0);

  useEffect(() => {
    setActive(props.active);
  }, [props.active])

  let className =
    'mobile-tabs' +
    (bottom ? ' mobile-tabs-bottom' : '') +
    (inSection ? ' mobile-tabs-insection' : '');

  const listTabs = tabs.map((section, index) => (
    <div
      className={
        'mobile-tabs__section' +
        (active === index ? ' mobile-tabs__section-active' : '')
      }
      key={index}
    >
      <a
        href={section.link}
        onClick={() => {
          setActive(index);
          section.onClick();
        }}
      >
        {section.name}
      </a>
    </div>
  ));

  var titleElement;
  if (title) {
    titleElement = (
      <div className="mobile-tabs__title">
        <h5 className="fluid">{title}</h5>
      </div>
    );
  }

  return (
    <div className={className}>
      {titleElement}
      <div className="mobile-tabs__container">{listTabs}</div>
    </div>
  );
};

export default MobileTabs;