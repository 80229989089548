import React from 'react';
import './Loader.scss';

export default class Loader extends React.Component {
  render() {
    const { color, small, message } = this.props;

    let className =
      'loader ' +
      (color ? ' loader-' + color : '') +
      (small ? ' loader-small' : '');

    var messageElement;
    if (message) {
      messageElement = <h5 className="loader__message">{message}</h5>;
    }

    return (
      <div className={className}>
        <div className="loader__spinner" />
        {messageElement}
      </div>
    );
  }
}
