const URLS = {
  '1': {
    family: {
      all: '/v1/vw/stock/families'
    },
    subfamily: {
      all: '/v1/vw/stock/subfamilies'
    },
    offer: {
      all: '/v1/vw/offers'
    },
    stock: {
      all: '/v1/vw/stock/stock'
    },
    savingPlan: {
      all: '/v1/vw/plan/find'
    }
  },
  '2': {
    family: {
      all: '/v1/gm/stock/families'
    },
    subfamily: {
      all: '/v1/gm/stock/subfamilies'
    },
    offer: {
      all: '/v1/gm/offers'
    },
    stock: {
      all: '/v1/gm/stock/stock'
    },
    savingPlan: {
      all: '/v1/gm/plan/find'
    }
  },
  '4': {
    family: {
      all: '/v2/nissan/family'
    },
    subfamily: {
      all: '/v2/nissan/subfamily'
    },
    offer: {
      all: '/v2/nissan/model/offers'
    },
    stock: {
      all: '/v2/nissan/model/stock',
      one: '/v2/nissan/stock'
    },
    savingPlan: {
      all: '/v2/nissan/plan/find'
    }
  },
  '6': {
    family: {
      all: '/v2/baic/family'
    },
    subfamily: {
      all: '/v2/baic/subfamily'
    },
    offer: {
      all: '/v2/baic/model/offers'
    },
    stock: {
      all: '/v2/baic/model/stock',
      one: '/v2/baic/stock'
    },
    savingPlan: {
      all: ''
    }
  },
  parameter: {
    all: '/api/v2/territorio/parameter'
  },
  serviceCategory: {
    all: '/api/v2/territorio/vehicle/service'
  },
  dealership: {
    start: '/api/v2/territorio/dealership/start',
    update: '/api/v2/territorio/dealership/update',
    mercadoPagoId: '/api/v2/territorio/dealership/mp'
  },
  credits: {
    all: '/v2/dealership/credit/find'
  }
};

export default URLS;