import React from 'react';
import Button from 'components/atoms/button/index.jsx';
import Salesman from 'components/atoms/salesman/index.jsx';
import PriceTable from 'components/molecules/priceTable/index.jsx';
import { connect } from 'react-redux';
import { getContacts, department } from 'services/contactMethod';
import * as dealershipActions from 'actions/dealership';

import { display, displayUSD } from 'helpers/price';

import './SummaryCard.scss';
import { getUSDrate } from 'actions/dealership';

class SummaryCard extends React.Component {
  state = {
    usdRate: 0,
  }

  componentDidMount() {
    if (this.props.vehicle.currency === 'usd') {
      getUSDrate().then((usdRate) => {
        this.setState({
          usdRate,
        });
        this.props.updateOperation({ ...this.props.operation, usd_rate: usdRate });
      });
    }
  }

  render() {
    const {
      vehicle,
      image,
      buyType,
      dark,
      back,
      noActions,
      full,
      title,
      priceDetail,
      promotion,
      brandId
    } = this.props;

    const displayPrice = vehicle.currency === 'usd' ? displayUSD : display;

    const whatsAppText = encodeURIComponent(
      `Quería consultar por el ${vehicle.name} chasis ${vehicle.getChassis()}`
    );

    const salesmen = getContacts(
      this.props.parameters,
      department.SALES,
      brandId
    );

    let className =
      'summary-card' +
      (dark ? ' summary-card-dark' : '') +
      (full ? ' summary-card-full' : '');

    //IMAGE FOR BACKGROUND TYPE
    if (image) {
      var headerBgr = {
        backgroundImage: "url('" + image + "')"
      };
    }

    //Back button

    var backElement;
    if (back) {
      backElement = <Button text="Volver" variant="ghost" back />;
    }

    //Actions

    var actionsElement;
    if (!noActions) {
      actionsElement = (
        <div className="summary-card__actions">
          <Button text="Siguiente" variant="main" />
          {backElement}
        </div>
      );
    }

    //Layout

    const pricesList = [];

    if (promotion) {
      pricesList.push({
        id: 6,
        name: `Código de descuento`,
        price: -vehicle.getPromotionDiscount(promotion)
      });
    }

    if (vehicle.currency === 'usd' && this.state.usdRate === 0) {
      pricesList.push({
        id: 7,
        name: `Cotización dólar reserva`,
        text: 'Confirmar en concesionario'
      });
    } else if (vehicle.currency === 'usd' && this.state.usdRate > 0) {
      pricesList.push({
        id: 7,
        name: `Cotización dólar reserva`,
        text: display(this.state.usdRate)
      });
    }

    var layoutElement;
    if (full) {
      layoutElement = (
        <div className="summary-card__details">
          <div className="summary-card__details__info">
            <strong>{buyType}</strong>
            {this.props.children}
          </div>
          <div className="summary-card__details__price">
            <PriceTable rows={[...priceDetail, ...pricesList]} small brandId={brandId} vehicle={vehicle} />
          </div>
        </div>
      );
    } else {
      layoutElement = (
        <div className="summary-card__details">
          <strong>{buyType}</strong>
          {this.props.children}
        </div>
      );
    }

    // Salesmen
    var salesmenElement;
    if (salesmen.length > 0) {
      salesmenElement = (
        <div className="card__salesmen">
          <p className="p-s">
            ¿Tenés una duda? Comunicate con nuestros vendedores:
          </p>

          {salesmen.map(salesman => (
            <Salesman
              key={salesman.id}
              name={salesman.fullname}
              zoom={salesman.zoom_link}
              whatsapp={salesman.whatsapp_number}
              whatsAppText={whatsAppText}
              initials={salesman.fullname.charAt(0).toUpperCase()}
            ></Salesman>
          ))}
        </div>
      );
    }

    return (
      <div className={className}>
        <div className="summary-card__header" style={headerBgr}>
          <h5>{title || 'Estas reservando'}</h5>
          <h3 className="summary-card__header__model">{vehicle.name}</h3>
          <h3 className="summary-card__header__price">
            {displayPrice(vehicle.getPromotionPrice(promotion))}
          </h3>
        </div>

        {layoutElement}
        {salesmenElement}
        {actionsElement}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    operation: state.dealership.operation,
    parameters: state.parameter.all
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOperation: operation => dispatch(dealershipActions.update(operation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryCard);
