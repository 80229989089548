const routes = {
  family: '/vehiculos-0km/:brand',
  families: '/vehiculos-0km',
  subfamilies: '/vehiculos-0km/versiones/:brand/:family/:id',
  buy: '/vehiculos-0km/reservar/:brand/:id/:family/:family_id/:model',
  buy_code:
    '/concesionario-digital/:brand/:id/:family/:family_id/:model/:operation_code',
  dealership_start:
    '/concesionario-digital/:brand/:id/:family/:family_id/:model',
  dealership_resume: '/concesionario-digital/continue/:operation_code',
  dealership_payment_selection:
    '/concesionario-digital/forma-de-pago/:operation_code',
  dealership_used_vehicle:
    '/concesionario-digital/vehiculo-usado/:operation_code',
  dealership_credit_selection:
    '/concesionario-digital/finaciacion/:operation_code',
  dealership_personal_information:
    '/concesionario-digital/tus-datos/:operation_code',
  dealership_review: '/concesionario-digital/revisar/:operation_code',
  dealership_payment: '/concesionario-digital/reserva/:operation_code',
  dealership_success: '/concesionario-digital/reservado/:operation_code',
  dealership_pending: '/concesionario-digital/pendiente/:operation_code',
  mercedes: '/mercedes-benz',
  ducati: '/ducati'
};

export default routes;

export const parseRoute = (route, parameters = {}) => {
  let parsedRoute = routes[route];

  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = beautifyParam(parameters[key]);

      parsedRoute = parsedRoute.replace(`:${key}`, value);
    }
  }

  return parsedRoute;
};

export const beautifyParam = param => {
  if (typeof param === 'string') {
    return param.toLowerCase().replace(/[\s\/]/g, '-');
  }

  return param;
};
