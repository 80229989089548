import React from 'react';
import './Textfield.scss';

export default class Textfield extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldState: 'pristine'
    };
    this.setFieldState = this.setFieldState.bind(this);
  }

  setFieldState(newState) {
    this.setState(state => ({
      fieldState: newState
    }));
  }
  render() {
    const {
      label,
      placeholder,
      disabled,
      error,
      errorMessage,
      helpText,
      required,
      half,
      third,
      twothird,
      fourth,
      file,
      type,
      ...etc
    } = this.props;

    let className =
      'textfield' +
      (error ? ' textfield-error' : '') +
      (label ? ' textfield-label' : '') +
      (half ? ' textfield-half' : '') +
      (third ? ' textfield-third' : '') +
      (twothird ? ' textfield-two-third' : '') +
      (fourth ? ' textfield-fourth' : '') +
      (required ? ' textfield-required' : '') +
      (file ? ' textfield-file' : '') +
      (this.state.fieldState ? ' textfield-' + this.state.fieldState : '') +
      (disabled ? ' textfield-disabled' : '');

    var labelElement;
    if (label) {
      labelElement = <div className="textfield__label">{label}</div>;
    }

    var helpElement;
    if (helpText) {
      helpElement = <div className="textfield__help-text">{helpText}</div>;
    }

    var messageElement;
    if (errorMessage) {
      messageElement = (
        <div className="textfield__error-message">{errorMessage}</div>
      );
    }
    var inputType = type;
    if (!inputType) {
      inputType = file ? 'file' : 'text';
    }

    return (
      <div className={className}>
        {labelElement}
        <input
          className="textfield__input"
          type={inputType}
          placeholder={placeholder}
          onFocus={e => this.setFieldState('focus', e)}
          onBlur={e => this.setFieldState('pristine', e)}
          required={required}
          {...etc}
        />
        {messageElement}
        {helpElement}
      </div>
    );
  }
}
