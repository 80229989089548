import React from 'react';
import { Link } from 'react-router-dom';
import { display, displayUSD } from 'helpers/price';
import { parseRoute } from 'config/routes';

import './PromotionCard.scss';

export default function OfferCard({ link, small, offer }) {
  let className =
    'offer-card promotion-card' + (small ? ' promotion-card-small' : '');

  const displayPrice = offer.currency === 'usd' ? displayUSD : display;

  link =
    link ||
    parseRoute('buy', {
      brand: offer.brand,
      id: offer.id,
      model: offer.name,
      family: offer.family,
      family_id: offer.family_id
    });

  return (
    <div className={className}>
      <Link to={link} target="_blank" className="promotion-card__link" />
      <div className="promotion-card__content">
        <h5 className="promotion-card__brand">{offer.brand}</h5>
        <h3 className="promotion-card__model">{offer.name}</h3>

        <div className="promotion-card__image">
          {offer.image && <img src={offer.image} alt={offer.name} />}
        </div>
        <h5 className="promotion-card__color">{offer.color}</h5>
        <div className="promotion-card__discount">
          <div className="promotion-card__discount-message">
            Descuento especial{' '}
            <span className="promotion-card__discount-message__price">
              -{displayPrice(offer.discount_amount)}
            </span>
          </div>
        </div>
      </div>
      <div className="promotion-card__action">
        <div className="promotion-card__units-info">Reservar online</div>
      </div>
    </div>
  );
};
