export default class Credit {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }

    this.id = parseInt(this.id);
    this.financed_max = parseFloat(this.financed_max);
  }
}
