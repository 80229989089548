import React from 'react';
import './PriceTable.scss';

import { display, displayUSD } from 'helpers/price';

export default class PriceTable extends React.Component {
  render() {
    const { rows, title, allowCode, small, brandId, vehicle } = this.props;

    if (!brandId) {
      throw new Error('Missing brandId');
    }

    const displayPrice = vehicle.currency === 'usd' ? displayUSD : display;
    const filteredRows = rows.filter(row => row.text || row.price !== 0);

    let className = 'price-table' + (small ? ' price-table-small' : '');
    const tableRows = filteredRows.map(row => (
      <div key={row.id} className="price-table__row">
        <div className="price-table__row__name">{row.name}</div>
        <div className="price-table__row__price">
          {row.text ? row.text : displayPrice(row.price)}
        </div>
      </div>
    ));

    var codeElement;
    if (allowCode) {
      codeElement = (
        <div className="price-table__code">
          <a href="/hay_que_hacer_que_agregue_el_campo">
            Tengo un código de descuento
          </a>
        </div>
      );
    }

    var titleElement;
    if (title) {
      titleElement = (
        <div className="price-table__title">
          <h4>{title}</h4>
        </div>
      );
    }

    return (
      <div className={className}>
        {titleElement}
        <div className="price-table__table">{tableRows}</div>
        {codeElement}
      </div>
    );
  }
}
