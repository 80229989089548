import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './containers/home/';
import News from './containers/news/';
import NewsPost from './containers/news/post';
import Models from './containers/models/';
import Plans from './containers/plans/';
import Versions from './containers/versions/';
import Promotions from './containers/promotions/';
import Contact from './containers/contact/';

import routes from 'config/routes';
import familyActions from 'actions/family';
import offerActions from 'actions/offer';
import parameterActions from 'actions/parameter';
import brands from 'config/brands';

//Digital Dealership
import StartEmail from './containers/digitalDealership/StartEmail/';
import StartConfirmation from './containers/digitalDealership/StartConfirmation/';
import PaymentMethods from './containers/digitalDealership/PaymentMethods/';
import PersonalInformation from './containers/digitalDealership/PersonalInformation/';
import Review from './containers/digitalDealership/Review/';
import Payment from './containers/digitalDealership/Payment/';
import Congrats from './containers/digitalDealership/Congrats/';
import PendingConfirmation from './containers/digitalDealership/Congrats/Pending';
import AddUsedVehicle from './containers/digitalDealership/PaymentMethods/UsedVehicle/AddUsedVehicle/';
import SelectCredit from './containers/digitalDealership/PaymentMethods/Plans/SelectPlan/';

import StoriesModal from 'components/organisms/storiesModal';

import { setVehicleImages } from 'actions/image';

import './resources/scss/main.scss';

const Popup = React.lazy(() => import('components/organisms/popup'));
const PlanPayment = React.lazy(() => import('./containers/buy/planPayment/'));
const HowToBuy = React.lazy(() => import('./containers/howToBuy/'));
const DucatiPage = React.lazy(() => import('./containers/ducati/'));
const Shifts = React.lazy(() => import('./containers/shifts/'));

class App extends Component {
  constructor(props) {
    super(props);

    const shouldPopupBeDisplayed =
      !window.location.href.includes('concesionario-digital') &&
      !window.location.href.includes('source=campaign');

    this.state = {
      liveChatInitialized: false,
      popupDisplayed: shouldPopupBeDisplayed
        ? sessionStorage.getItem('popupDisplayed')
        : true
    };
  }

  componentDidMount() {
    this.props.fetchFamilies();
    this.props.fetchOffers();
    this.props.fetchParameters();

    this.checkLiveChat();
  }

  checkLiveChat() {
    if (
      (process.env && process.env.NODE_ENV === 'development') ||
      this.state.liveChatInitialized
    ) {
      return;
    }

    this.initLiveChat();
  }

  initLiveChat() {
    this.setState({ liveChatInitialized: true });

    (function() {
      let js = document.createElement('script');
      js.type = 'text/javascript';
      js.async = 1;
      js.src = 'https://go.botmaker.com/rest/webchat/p/L0S6M50J7S/init.js';
      document.body.appendChild(js);
    })();
  }

  render() {
    const { popupDisplayed } = this.state;
    const { parameters } = this.props;

    if (!popupDisplayed && parameters && parameters.popups.length > 0) {
      return (
        <Router>
          <Suspense fallback="">
            <Popup
              popups={parameters.popups}
              onClose={() => {
                this.setState({ popupDisplayed: true });
                sessionStorage.setItem('popupDisplayed', true);
              }}
            />
          </Suspense>
        </Router>
      );
    }

    return (
      <Router>
        <div>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route
              path="/cv-recibido"
              exact
              render={() => <Home resumeSuccess />}
            />
            <Route path="/cv-error" exact render={() => <Home resumeError />} />
            <Route path={routes.family} exact component={Models} />
            <Route path={routes.families} exact component={Models} />
            <Route
              path={routes.subfamilies}
              exact
              render={props => (
                <Versions {...props} key={props.match.params.id} />
              )}
            />
            <Route path="/planes-de-ahorro" exact component={Plans} />
            <Route path="/oportunidades" exact component={Promotions} />
            <Route path="/contacto" exact component={Contact} />
            <Route path="/pagar-plan" exact>
              <Suspense fallback="Cargando...">
                <PlanPayment />
              </Suspense>
            </Route>
            <Route path="/ducati" exact>
              <Suspense fallback="Cargando...">
                <DucatiPage />
              </Suspense>
            </Route>
            <Route path="/postventa" exact>
              <Suspense fallback="Cargando...">
                <Shifts />
              </Suspense>
            </Route>
            <Route path="/como-reservar" exact>
              <Suspense fallback="Cargando...">
                <HowToBuy />
              </Suspense>
            </Route>
            <Route path={routes.buy_code} exact component={StartEmail} />
            <Route path={routes.buy} exact component={StartConfirmation} />
            <Route
              path={routes.dealership_start}
              exact
              component={StartEmail}
            />
            <Route
              path={routes.dealership_payment_selection}
              exact
              component={PaymentMethods}
            />
            <Route
              path={routes.dealership_used_vehicle}
              exact
              component={AddUsedVehicle}
            />
            <Route
              path={routes.dealership_credit_selection}
              exact
              component={SelectCredit}
            />
            <Route
              path={routes.dealership_personal_information}
              exact
              component={PersonalInformation}
            />
            <Route path={routes.dealership_review} exact component={Review} />
            <Route path={routes.dealership_payment} exact component={Payment} />
            {process.env.NODE_ENV === 'production' && (
              <Route
                path={routes.dealership_resume}
                exact
                component={() => window.location.reload()}
              />
            )}
            <Route
              path={routes.dealership_success}
              exact
              component={Congrats}
            />
            <Route
              path={routes.dealership_pending}
              exact
              component={PendingConfirmation}
            />
            <Route path="/noticias" exact component={News} />
            <Route path="/noticias/:id/:title" exact component={NewsPost} />
            <Route path="/historias/:id/:title" exact component={StoriesModal} />
          </Switch>
        </div>
      </Router>
    );
  }

  componentDidUpdate(prevProps) {
    const { parameters, families, offers, allFamilies, plans } = this.props;

    this.checkLiveChat();

    if (!parameters) {
      return;
    }

    if (
      parameters !== prevProps.parameters ||
      families.length !== prevProps.families.length ||
      offers.length !== prevProps.offers.length ||
      allFamilies.length !== prevProps.allFamilies.length ||
      plans.length !== prevProps.plans.length
    ) {
      this.props.setVehicleImages(parameters.vehicles);
    }
  }
}

const mapStateToProps = state => {
  return {
    parameters: state.parameter.all,
    families: state.family.all,
    allFamilies: state.families.all,
    offers: state.offer.all,
    plans: state.savingPlan.all
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFamilies: () => dispatch(familyActions.fetchAll()),
    fetchParameters: () => dispatch(parameterActions.fetchAll()),
    fetchOffers: () => {
      dispatch(offerActions.fetchAll({ brandId: brands.volkswagen.id }));
      dispatch(offerActions.fetchAll({ brandId: brands.chevrolet.id }));
      dispatch(offerActions.fetchAll({ brandId: brands.nissan.id }));
      dispatch(offerActions.fetchAll({ brandId: brands.baic.id }));
    },
    setVehicleImages: vehicles => dispatch(setVehicleImages(vehicles))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
