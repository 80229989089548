import Vehicle from 'models/vehicle';

const defaultState = {
  requesting: false,
  error: false,
  all: null,
  bySubfamily: {},
  selected: undefined
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `FETCH_ALL_STOCK_RESET`:
      return { ...defaultState };

    case `FETCH_ALL_STOCK_SENT`:
      return { ...state, ...defaultState, requesting: true };

    case `FETCH_ALL_STOCK_FAILED`:
      return { ...state, requesting: false, error: true };

    case `FETCH_ALL_STOCK_SUCCESS`:
      const vehicles = transformPayload(action.payload, action.brandId);

      const all = state.all || [];

      return {
        ...state,
        all: all.concat(vehicles),
        bySubfamily: {
          ...state.bySubfamily,
          [action.query.subfamily_id]: {
            all: vehicles,
            best: orderByDiscount(vehicles)
          }
        },
        requesting: false
      };

    case `FETCH_ONE_STOCK_SENT`:
      return { ...state, selected: undefined, requesting: true };

    case `FETCH_ONE_STOCK_FAILED`:
      return { ...state, requesting: false, selected: null, error: true };

    case `FETCH_ONE_STOCK_SUCCESS`:
      let payload = null || action.payload;
      if (payload && payload.length > 0) {
        payload = payload[0];
      }

      return {
        ...state,
        selected: payload ? new Vehicle(payload) : null
      };

    default:
      return state;
  }
};

function transformPayload(vehicles = [], brandId) {
  try {
    return vehicles.map(offer => new Vehicle({ ...offer, brand_id: brandId }));
  } catch (e) {
    console.error(e);

    return [];
  }
}

function orderByDiscount(vehicles) {
  return vehicles.sort((a, b) => {
    return a.discount - b.discount;
  });
}
