import Service from './service';

export default class ServiceCategory {
  services = [];

  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  set model_services(services) {
    this.services = services.map(service => new Service(service));
  }
}
