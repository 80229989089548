import fetch from 'isomorphic-fetch';
import urls from 'config/resource/urls';

export const getUSDrate = async () => {
  const apiUrl = process.env.REACT_APP_INTERNAL_API_URL;
  try {
    const response = await fetch(`${apiUrl}/v2/baic/usd/rate`);
    const data = await response.json();
    return data.value;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export const start = data => {
  return dispatch => {
    dispatch(started());

    const apiUrl = process.env.REACT_APP_API_URL;
    const path = urls.dealership.start;

    return fetch(`${apiUrl}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        if (json.data) {
          dispatch(startSucceeded(json.data));
        } else {
          dispatch(startFailed());
        }
      })
      .catch(e => {
        console.error(e);
        dispatch(startFailed());
      });
  };
};

export const reset = () => ({
  type: `START_DEALERSHIP_RESET`
});

const started = response => ({
  type: `START_DEALERSHIP_SENT`
});

const startFailed = () => ({
  type: `START_DEALERSHIP_FAILED`
});

const startSucceeded = payload => ({
  type: `START_DEALERSHIP_SUCCEEDED`,
  payload
});

const updateFailed = () => ({
  type: `UPDATE_DEALERSHIP_OPERATION_FAILED`
});

const updating = payload => ({
  type: `UPDATE_DEALERSHIP_OPERATION_STARTED`
});

const updateSucceeded = payload => ({
  type: `UPDATE_DEALERSHIP_OPERATION_SUCCEEDED`,
  payload
});

export const update = operation => {
  return dispatch => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const path = urls.dealership.update;

    const data = { ...operation };

    dispatch(updating());

    return fetch(`${apiUrl}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        if (json.data) {
          dispatch(updateSucceeded(json.data));
        } else {
          dispatch(updateFailed());
        }
      })
      .catch(e => {
        console.error(e);
        dispatch(updateFailed());
      });
  };
};

/* ------------------- Purchase ------------------- */
export const vehiclePurchaseRequested = () => ({
  type: 'REQUEST_DEALERSHIP_PURCHASE'
});

export const purchaseVehicle = data => {
  return function(dispatch) {
    dispatch(vehiclePurchaseRequested());

    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v2/territorio/dealership/purchase`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: data })
      }
    )
      .then(response => response.json())
      .then(response => {
        if (!response || !response.metadata) {
          dispatch(
            vehiclePurchaseError(
              'Error desconocido. Por favor reintentar más tarde.'
            )
          );
        }

        if (response.metadata.success) {
          dispatch(vehiclePurchased());
        }

        if (response.metadata.success !== true) {
          dispatch(vehiclePurchaseError(response.metadata.message));
        }
      })
      .catch(() => dispatch(vehiclePurchaseError()));
  };
};

export const initPurchase = response => ({
  type: 'INIT_DEALERSHIP_PURCHASE'
});

export const vehiclePurchased = response => ({
  type: 'DEALERSHIP_PURCHASE_SUCCESSFUL'
});

export const vehiclePurchaseError = error => {
  return {
    type: 'DEALERSHIP_PURCHASE_ERROR',
    error: error
  };
};

export const getMercadoPagoId = ({ code }) => {
  return dispatch => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const path = urls.dealership.mercadoPagoId;

    return fetch(`${apiUrl}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code })
    })
      .then(response => response.json())
      .then(json => {
        if (json.data) {
          dispatch(updateSucceeded(json.data));
        } else {
          console.error(json);
        }
      })
      .catch(e => {
        console.error(e);
      });
  };
};
