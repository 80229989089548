import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ybrand from 'resources/img/logo-y-orange.svg';
import whatsapp from 'resources/img/whatsapp-header.svg';
import zoom from 'resources/img/zoom.svg';
import skype from 'resources/img/skype.svg';
import contact from 'resources/img/contact-icon-header.svg';
import routes from 'config/routes';
import { getContacts, department } from 'services/contactMethod';
import './Header.scss';

class Header extends React.Component {
  render() {
    const { active } = this.props;

    const methods = getContacts(this.props.parameters, department.ADMIN);

    let videocallLink = '';
    let waNumber = '';
    if (methods.length > 0) {
      videocallLink = methods[0].zoom_link;
      waNumber = methods[0].whatsapp_number;
    }

    let className = 'header header-covid';

    return (
      <div className={className}>
        <div className="covid-message"></div>
        <div className="container">
          <div className="col col-ten">
            <div className="header__logo">
              <Link to="/">
                <img src={ybrand} alt="Territorio Yacopini" />
              </Link>
            </div>
            <div className="header__navigation">
              <div
                className={
                  'header__navigation__link ' +
                  (active === 1 ? ' header__navigation__link-active' : '')
                }
              >
                <Link to={routes.families}>Vehículos 0Km</Link>
              </div>
              <div
                className={
                  'header__navigation__link ' +
                  (active === 5 ? ' header__navigation__link-active' : '')
                }
              >
                <Link to={routes.ducati}>Ducati</Link>
              </div>
              <div
                className={
                  'header__navigation__link ' +
                  (active === 2 ? ' header__navigation__link-active' : '')
                }
              >
                <Link to="/oportunidades">Oportunidades</Link>
              </div>
              <div
                className={
                  'header__navigation__link ' +
                  (active === 3 ? ' header__navigation__link-active' : '')
                }
              >
                <Link to="/planes-de-ahorro">Planes de Ahorro</Link>
              </div>
              <div
                className={
                  'header__navigation__link ' +
                  (active === 4 ? ' header__navigation__link-active' : '')
                }
              >
                <div className="header__navigation__link-menu">
                  <span>
                    <Link to="/postventa">Postventa</Link>
                  </span>
                  <div className="header__navigation__link__dropdown">
                    <a
                      href="https://www.territorioyacopini.com.ar/solicitar-turno/chevrolet"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Solicitar turno Chevrolet
                    </a>
                    <a
                      href="https://www.territorioyacopini.com.ar/solicitar-turno/volkswagen"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Solicitar turno Volkswagen
                    </a>
                    <a
                      href="https://www.territorioyacopini.com.ar/solicitar-turno/nissan"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Solicitar turno Nissan
                    </a>
                  </div>
                </div>
              </div>

              <div className={'header__navigation__link '}>
                <a
                  href="https://territorioyacopini.com.ar/usados/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Usados
                </a>
              </div>
            </div>
          </div>
          <div className="header__info col col-two ">
            <div className="header__info__text">
              Llamá al +54 261 4439300
              <br />
              San Martín Sur 600
            </div>
            {!videocallLink && (
              <div
                className={
                  'header__info__social' +
                  (active === 5 ? ' header__info__social-active' : '')
                }
              >
                <Link to="/contacto">
                  <img src={contact} alt="Contacto" />
                </Link>
              </div>
            )}
            {videocallLink && (
              <div className="header__info__social">
                <a
                  href={videocallLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {videocallLink.includes('zoom') && (
                    <img
                      src={zoom}
                      width="40"
                      alt="Zoom"
                      title="Contactanos por videollamada"
                    />
                  )}
                  {videocallLink.includes('skype') && (
                    <img
                      src={skype}
                      width="40"
                      alt="Sykpe"
                      title="Contactanos por videollamada"
                    />
                  )}
                  {parseInt(videocallLink) > 0 && (
                    <img
                      src={`tel:${parseInt(videocallLink)}`}
                      width="40"
                      alt="WhatsApp"
                      title="Llamanos por WhatsApp"
                    />
                  )}
                </a>
              </div>
            )}
            {waNumber && (
              <div className="header__info__social">
                <a
                  href={`https://web.whatsapp.com/send?phone=${waNumber}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={whatsapp} alt="WhatsApp" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    parameters: state.parameter.all
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
