import DealershipOperation from 'models/dealershipOperation';

const purchaseDefaultState = {
  purchasing: false,
  purchaseError: null,
  purchaseFailed: false,
  purchaseSuccess: false
};

const defaultState = {
  ...purchaseDefaultState,
  operation: undefined,
  error: false,
  requesting: false,
  updating: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `START_DEALERSHIP_RESET`:
      return { ...defaultState };

    case `START_DEALERSHIP_SENT`:
      return { ...state, ...defaultState, requesting: true, error: false };

    case `START_DEALERSHIP_FAILED`:
      return { ...state, operation: null, requesting: false, error: true };

    case `START_DEALERSHIP_SUCCEEDED`:
      return {
        ...state,
        requesting: false,
        operation: new DealershipOperation(action.payload)
      };

    case `UPDATE_DEALERSHIP_OPERATION_STARTED`:
      return {
        ...state,
        updating: true
      };

    case `UPDATE_DEALERSHIP_OPERATION_SUCCEEDED`:
      return {
        ...state,
        updating: false,
        operation: action.payload
          ? new DealershipOperation(action.payload)
          : null
      };
    case 'INIT_DEALERSHIP_PURCHASE':
      return { ...state, ...purchaseDefaultState };
    case 'REQUEST_DEALERSHIP_PURCHASE':
      return { ...state, ...purchaseDefaultState, purchasing: true };
    case 'DEALERSHIP_PURCHASE_SUCCESSFUL':
      return { ...state, ...purchaseDefaultState, purchaseSuccess: true };
    case 'DEALERSHIP_PURCHASE_ERROR':
      return {
        ...state,
        ...purchaseDefaultState,
        purchaseError: action.error,
        purchaseFailed: true
      };
    default:
      return state;
  }
};
