import React from 'react';
import check from 'resources/img/check.svg';

import './SelectCard.scss';

const SelectCard = ({
  text,
  helpText,
  image,
  link,
  active,
  disabled,
  full,
  onClick,
  value,
  selectedValue
}) => {
  const isActive = value === selectedValue;

  let className =
    'select-card' +
    (isActive ? ' select-card-active' : '') +
    (disabled ? ' select-card-disabled' : '') +
    (full ? ' select-card-full' : '');
  var imageElement;
  if (image) {
    imageElement = (
      <div className="select-card__image">
        <img src={image} alt={text} />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="select-card__check">
        <img src={check} alt="Seleccionado" />
      </div>
      {link && (
        <a href={link} className="select-card__link">
          {text}
        </a>
      )}
      {!link && (
        <span
          className="select-card__link"
          onClick={() => (onClick ? onClick(value) : '')}
        >
          {text}
        </span>
      )}
      {imageElement}
      <h4 className="select-card__text">{text}</h4>
      <div className="select-card__help-text">{helpText}</div>
    </div>
  );
};

export default SelectCard;
