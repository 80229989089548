import React from 'react';
import Arrowlink from 'components/atoms/arrowLink/index.jsx';

export default () => (
  <div className="section  section-center section-alt home__contact">
    <div className="container">
      <div className="col col-six col-m-twelve">
        <h5>Visitanos en</h5>
        <h2>
          San Martín Sur 600, <br />
          Godoy Cruz, Mendoza.{' '}
        </h2>
        <p>
          <Arrowlink
            text="Ver mapa"
            onClick={() => window.open('https://goo.gl/maps/Pv5wkLwZ4pB2')}
          />
        </p>
      </div>
    </div>
  </div>
);
