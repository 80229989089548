import fetch from 'isomorphic-fetch';
import objectToParameters from 'helpers/objectToParameters';
import urls from 'config/resource/urls';

export const fetchAll = (prefix, { apiUrl, resourceName } = {}) => {
  const actionPrefix = prefix.toUpperCase();
  const resource = resourceName || prefix;

  const fetchAll = ({ query, brandId } = {}) => {
    return (dispatch, getState) => {
      if (!brandId) {
        const {
          brand: { active }
        } = getState();

        brandId = active;
      }

      dispatch(fetchAllRequested());

      const parameters = query ? `?${objectToParameters(query)}` : '';
      const path = getPath({ brandId, resource });

      apiUrl = apiUrl || process.env.REACT_APP_INTERNAL_API_URL;

      return fetch(`${apiUrl}${path}${parameters}`, {
        method: 'GET'
      })
        .then(response => response.json())
        .then(json => {
          if (json.data) {
            dispatch(resourcesReceived(json.data, brandId, query));
          } else {
            dispatch(fetchAllFailed());
          }
        })
        .catch(e => {
          console.error(e);
          dispatch(fetchAllFailed());
        });
    };
  };

  const fetchAllRequested = response => ({
    type: `FETCH_ALL_${actionPrefix}_SENT`
  });

  const fetchAllFailed = () => ({
    type: `FETCH_ALL_${actionPrefix}_FAILED`
  });

  const resourcesReceived = (payload, brandId, query) => ({
    type: `FETCH_ALL_${actionPrefix}_SUCCESS`,
    payload,
    brandId,
    query
  });

  const reset = response => ({
    type: `FETCH_ALL_${actionPrefix}_RESET`
  });

  return {
    fetchAll,
    reset
  };
};

export const fetchOne = (prefix, { apiUrl, resourceName } = {}) => {
  const actionPrefix = prefix.toUpperCase();
  const resource = resourceName || prefix;

  const fetchOne = ({ id, brandId, query } = { query: true }) => {
    return (dispatch, getState) => {
      if (!brandId) {
        const {
          brand: { active }
        } = getState();

        brandId = active;
      }

      dispatch(fetchOneRequested());

      const path = getOnePath({ brandId, resource });

      apiUrl = apiUrl || process.env.REACT_APP_INTERNAL_API_URL;

      const extra = query ? `?id=${id}` : `/${id}`;

      return fetch(`${apiUrl}${path}${extra}`, {
        method: 'GET'
      })
        .then(response => response.json())
        .then(json => {
          const data = Array.isArray(json.data) ? json.data[0] : json.data;

          if (data) {
            dispatch(resourceReceived(json.data, brandId));
          } else {
            dispatch(fetchOneFailed());
          }
        })
        .catch(e => {
          console.error(e);
          dispatch(fetchOneFailed());
        });
    };
  };

  const fetchOneRequested = response => ({
    type: `FETCH_ONE_${actionPrefix}_SENT`
  });

  const fetchOneFailed = () => ({
    type: `FETCH_ONE_${actionPrefix}_FAILED`
  });

  const resourceReceived = (payload, brandId) => ({
    type: `FETCH_ONE_${actionPrefix}_SUCCESS`,
    payload,
    brandId
  });

  return {
    fetchOne
  };
};

function getPath({ brandId, resource }) {
  return urls[brandId][resource]
    ? urls[brandId][resource].all
    : urls[resource].all;
}

function getOnePath({ brandId, resource }) {
  return urls[brandId][resource]
    ? urls[brandId][resource].one || urls[brandId][resource].all
    : urls[resource].one || urls[resource].all;
}
