export default class Family {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  set discount(discount) {
    if (!discount) {
      this._discount = 0;
      return;
    }

    this._discount = parseFloat(discount);
  }

  get discount() {
    return this._discount;
  }

  get webDiscount() {
    return this.discount;
  }
}
