import React, { useState } from 'react';
import './StickyNav.scss';

export default ({ active, sections, sticky, big }) => {
  const [activeSection, setActiveSection] = useState(0);

  const onClick = section => {
    setActiveSection(sections.indexOf(section));

    if (section.onClick) {
      section.onClick(section);
    }
  };

  const className =
    'sticky-nav' +
    (sticky ? ' sticky-nav-sticky' : '') +
    (big ? ' sticky-nav-big' : '');

  return (
    <div className={className}>
      <div className="container">
        <div className="col col-twelve">
          <div className="sticky-nav__container">
            {sections.map((section, i) => (
              <div
                key={section.name}
                className={
                  'sticky-nav__section' +
                  (activeSection === i ? ' sticky-nav__section-active' : '')
                }
              >
                <a href={section.link} onClick={() => onClick(section)}>
                  {section.name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
