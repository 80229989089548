import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Responsive from 'react-responsive';
import FlowProgressBar from 'containers/digitalDealership/FlowProgressBar.jsx';
import FlowNavigation from 'components/molecules/flowNavigation/index.jsx';
import FlowHeader from 'components/molecules/flowHeader/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import BalanceInformation from 'components/molecules/balanceInformation/index.jsx';
import Select from 'components/molecules/select/index.jsx';

import * as dealershipActions from 'actions/dealership';
import { parseRoute } from 'config/routes';

import '../DigitalDealership.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

const options = {
  status: [
    {
      title: 'Casado/a',
      value: 1
    },
    {
      title: 'Soltero/a',
      value: 2
    },
    {
      title: 'Divorciado/a',
      value: 3
    },
    {
      title: 'Viudo/a',
      value: 4
    }
  ]
};

class PersonalInformation extends React.Component {
  constructor(props) {
    super(props);

    const operation = props.operation || { purchase: {} };

    this.state = {
      data: {
        name: operation.customer ? operation.customer.name : '',
        lastname: operation.customer ? operation.customer.lastname : '',
        email: operation.customer ? operation.customer.email : '',
        telephone: operation.customer ? operation.customer.telephone : '',
        birthdate:
          operation.purchase.birthdate !== '0000-00-00'
            ? operation.purchase.display_birthdate
            : '',
        marital_status_id: operation.purchase.marital_status_id || '',
        address: operation.customer ? operation.customer.address : '',
        dni: operation.customer ? operation.customer.dni : ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });

    this.updateOperation();
  }

  handleSelectChange({ value }) {
    this.setState({
      data: {
        ...this.state.data,
        marital_status_id: value
      }
    });

    this.updateOperation();
  }

  render() {
    const { vehicle, operation } = this.props;
    const { data } = this.state;
    const { operation_code } = this.props.match.params;

    if (!operation || !vehicle) {
      return (
        <Redirect
          to={parseRoute('dealership_resume', {
            operation_code: operation_code
          })}
        />
      );
    }

    const missingData =
      !data.name ||
      !data.lastname ||
      !data.telephone ||
      !data.email ||
      !data.dni ||
      !data.birthdate ||
      !data.marital_status_id;

    return (
      <div className="digital-dealership digital-dealership__personal-information">
        <FlowHeader
          small
          title="Concesionario Digital"
          subtitle={`Reservando ${vehicle.name}`}
        />
        <BalanceInformation
          operation={operation}
          cashAmount={operation.cash_amount}
          vehicle={vehicle}
        />
        <div className="section">
          <div className="container">
            <div className="col col-two col-not-mobile " />
            <div className="col col-eight col-m-twelve ">
              <h5>Compra Online</h5>
              <h2>2. Datos personales</h2>
              <Desktop>
                <p className="p">
                  Completa tus datos para que podamos contactarte e iniciar los
                  tramites.
                </p>
              </Desktop>
              <Mobile>
                Completa tus datos para que podamos contactarte e iniciar los
                tramites.
              </Mobile>
            </div>
          </div>
        </div>
        <div className="section section-small">
          <div className="container">
            <div className="col col-two col-not-mobile " />
            <div className="col col-six col-m-twelve ">
              <div className="digital-dealership__fieldset">
                <h4>Datos personales</h4>
                <div>
                  <Textfield
                    label="DNI"
                    half
                    value={data.dni}
                    name="dni"
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <Textfield
                    label="Nombre"
                    half
                    value={data.name}
                    name="name"
                    onChange={this.handleChange}
                  />
                  <Textfield
                    label="Apellido"
                    half
                    value={data.lastname}
                    name="lastname"
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <Textfield
                    label="Fecha de nacimiento"
                    half
                    value={data.birthdate}
                    name="birthdate"
                    onChange={this.handleChange}
                  />
                  <Select
                    label="Estado Civil"
                    list={options.status}
                    half
                    handleChange
                    value={data.marital_status_id}
                    name="marital_status_id"
                    onChange={this.handleSelectChange}
                  />
                </div>
              </div>

              <div className="digital-dealership__fieldset">
                <h4>Datos de contacto</h4>
                <div>
                  <Textfield
                    label="Dirección"
                    value={data.address}
                    name="address"
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <Textfield
                    label="Teléfono"
                    placeholder="Ej. 261 5 123 456"
                    half
                    value={data.telephone}
                    name="telephone"
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <Textfield
                    type="email"
                    label="Email"
                    twothird
                    value={data.email}
                    name="email"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <FlowNavigation progressBar={<FlowProgressBar step={2} />}>
          <Link to={parseRoute('dealership_review', { operation_code })}>
            <Button text="Continuar" variant="main" disabled={missingData} />
          </Link>
          <Link
            to={parseRoute('dealership_payment_selection', { operation_code })}
          >
            <Button text="Volver" back />
          </Link>
        </FlowNavigation>
      </div>
    );
  }

  updateOperation() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => this.doUpdateOperation(), 750);
  }

  doUpdateOperation() {
    const { operation } = this.props;
    const { data } = this.state;

    operation.customer = operation.customer || {};

    operation.customer.name = data.name;
    operation.customer.lastname = data.lastname;
    operation.customer.email = data.email;
    operation.customer.telephone = data.telephone;
    operation.purchase.birthdate = data.birthdate;
    operation.purchase.marital_status_id = data.marital_status_id;
    operation.customer.address = data.address;
    operation.customer.dni = data.dni;

    this.props.updateOperation(operation);
  }
}

const mapStateToProps = state => {
  return {
    operation: state.dealership.operation,
    vehicle: state.stock.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOperation: operation => dispatch(dealershipActions.update(operation))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformation);
